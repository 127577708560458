@use 'sass:math';
// Utilities : Paddings, Margins
$spaces: (0,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20);
$sides: (top, right, bottom, left);

// Bootstrap utility classes:
// p-0: [padding: 0rem]
// ml-0: [margin-left: 0rem]

// pl-1 : [ padding-left: 0.25rem ]
// mr-2 : [ margin-right: 0.5rem ]
// pt-3 : [ padding-top: 0.75rem ]
// mb-4 : [ margin-bottom: 1rem ]

// pl-5 : [ padding-left: 1.25rem ]
// mr-6 : [ margin-right: 1.5rem ]
// pt-7 : [ padding-top: 1.75rem ]
// mb-8 : [ margin-bottom: 2rem ]

@each $space in $spaces {
  .m-#{$space} {
    margin: #{calc($space * 4)}px !important;
  }
  .p-#{$space} {
    padding: #{calc($space * 4)}px !important;
  }
  .mx-#{$space} {
    margin-left: #{calc($space * 4)}px !important;
    margin-right: #{calc($space * 4)}px !important;
  }
  .my-#{$space} {
    margin-bottom: #{calc($space * 4)}px !important;
    margin-top: #{calc($space * 4)}px !important;
  }
  .px-#{$space} {
    padding-left: #{calc($space * 4)}px !important;
    padding-right: #{calc($space * 4)}px !important;
  }
  .py-#{$space} {
    padding-bottom: #{calc($space * 4)}px !important;
    padding-top: #{calc($space * 4)}px !important;
  }
  @each $side in $sides {
    .m#{str-slice($side, 0, 1)}-#{$space} {
      margin-#{$side}: #{calc($space * 4)}px !important;
    }
    .p#{str-slice($side, 0, 1)}-#{$space} {
      padding-#{$side}: #{calc($space * 4)}px !important;
    }
  }
}

// Bootstrap style of row, cols spacing ( Every row contains 12 columns )
// Large screen: .col-lg-#{space}
// Medium screen: col-md-#{space}
// Small screen: col-sm-#{space}
// Extremely small screen: col-xs-#{space}
// Utilization
// <div class="row">
//   <div class="col-lg-3 col-md-6 col-sm-12 col-xs-12"></div>
//   <div class="col-lg-3 col-md-6 col-sm-12 col-xs-12"></div>
//   <div class="col-lg-3 col-md-6 col-sm-12 col-xs-12"></div>
//   <div class="col-lg-3 col-md-6 col-sm-12 col-xs-12"></div>
// </div>

.row {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: -12px;

  .row {
    margin: 0;
  }
}

.justify-start {
  justify-content: flex-start !important;
}

.justify-center {
  justify-content: center !important;
}

@media only screen and (max-width: 600px) {
  .justify-sm-center {
    justify-content: center !important;
  }
}

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
}

[class*=col-] {
  box-sizing: border-box;
  padding: 12px;
}

$cols: (1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12);

@each $col in $cols {
  .col-#{$col} {
    flex: 0 0 calc(100% / 12 * #{$col});
    max-width: calc(100% / 12 * #{$col});
  }
}

@media only screen and (max-width: 600px) {
  @each $col in $cols {
    .col-xs-#{$col} {
      flex: 0 0 calc(100% / 12 * #{$col});
      max-width: calc(100% / 12 * #{$col});
    }
  }
}

@media only screen and (min-width: 600px) {
  @each $col in $cols {
    .col-sm-#{$col} {
      flex: 0 0 calc(100% / 12 * #{$col});
      max-width: calc(100% / 12 * #{$col});
    }
  }
}

@media only screen and (min-width: 900px) {
  @each $col in $cols {
    .col-md-#{$col} {
      flex: 0 0 calc(100% / 12 * #{$col});
      max-width: calc(100% / 12 * #{$col});
    }
  }
}
