/* Menus */

/* main menu */
.nav-wrapper {
  position: sticky;
  height: 42px;
  width: 100%;
  top: 0px;
  transition: box-shadow .2s ease, background .2s ease;
  background-color: $color-brand-blue;
  z-index: 2;
  color: rgba(255, 255, 255, 1);
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.5);

  &.floatAbove {
    position: fixed;
    top: 0px;
    left: 0px;
  }
}

@include media-max-menu {
  .main-nav {
    display: none;
  }

  .mobile-nav {
    @include animate(left);
    align-items: center;
    display: flex;
    height: 42px;
    position: relative;
    left: 0;

    &.is-open {
      left: 230px;
    }
  }

  .main-nav__tabs-wrapper {
    @include animate(height);
    height: 0;
    overflow: hidden;
  }

  // .main-nav__tabs {
  //   @include animate(height);
  //   height: 0;
  //   overflow: hidden;
  // }
}
@include media-min-menu {
  .mobile-nav {
    display: none;
  }

  .main-nav {
    font-family: "Noto Sans", sans-serif;
    font-size: 20px;
    line-height: 1;
    display: flex;
    flex-direction: row;
    height: 42px;
    position: relative;

    .main-nav__item {
      @include animate(background-color);
      background-color: rgba(0, 60, 128, 0);
      flex: 1 0;
      margin: 0;
      padding: 0;

      &:focus,
      &:hover {
        background-color: $color-brand-blue-hover;

        .main-nav__tabs {
          opacity: 1;
          pointer-events: all;
        }
      }

      &.is-linear {
        position: relative;
      }
    }

    .main-nav__label {
      &,
      &:link,
      &:visited {
        align-items: center;
        color: $color-white;
        cursor: pointer;
        display: flex;
        font-family: 'Noto Sans', sans-serif;
        font-size: 18px;
        font-weight: 700;
        height: 42px;
        justify-content: center;
        line-height: 1;
        padding: 0 8px;
        text-shadow: 0 1px 3px rgb(0 0 0 / 30%);

        @include media-min-md {
          font-size: 20px;
        }
      }
    }

    .tab-item {
      width: calc(25% - 10px);

      a:link,
      a:visited {
        align-items: center;
        display: flex !important;
        font: 700 14px/20px 'Noto Sans', sans-serif;
        height: 26px !important;
        padding: 3px !important;
        text-align: left;
      }
    }
  }

  .main-nav__tabs {
    @include animate(all);
    border-radius: 0 0 $border-radius $border-radius;
    background-color: rgb(16, 146, 195);
    background-color: rgb(0, 60, 128);
    box-shadow: 0 3px 2px -1px rgba(0, 0, 0, 0.5);
    display: flex;
    flex-wrap: wrap;
    left: 0px;
    opacity: 0;
    pointer-events: none;
    position: absolute;
    top: 42px;
    z-index: 2;

    // .is-active & {
    //   opacity: 1;
    //   pointer-events: all;
    // }

    .is-linear & {
      flex-direction: column;
      min-width: 100%;
    }
  }

  .main-nav__tab-item {
    font-family: 'Noto Sans', sans-serif;
    font-size: 16px;
    font-weight: 700;
    min-height: 26px;
    line-height: 20px;
    margin: 5px;
    width: calc(33% - 10px);

    @include media-min-md() {
      width: calc(25% - 10px);
    }

    .is-linear & {
      min-width: calc(100% - 10px);
    }
  }

  .main-nav__tab-link {
    &,
    &:link,
    &:visited {
      @include animate(background);
      align-items: center;
      background: rgba(0, 0, 0, 0);
      color: $color-white;
      display: flex;
      min-height: 26px;
      padding: 3px 5px;
      width: calc(100% - 10px);
    }

    &:focus,
    &:hover {
      background: rgba(0, 0, 0, .5);
    }

    .is-linear & {
      white-space: nowrap;
    }
  }
}

.mobile-drawer {
  @include animate(transform opacity);
  background: $color-white;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  height: 100vh;
  opacity: 0;
  overflow: hidden;
  position: fixed;
  top: 0;
  transform: translateX(-230px);
  width: 220px;
  z-index: 10;

  &.is-open {
    opacity: 1;
    transform: translateX(0);
  }
}

.mobile-drawer__header {
  box-sizing: border-box;
  display: block;
  height: 80px;
  padding: 10px;
  width: 220px;
}

.mobile-drawer__list {
  height: calc(100vh - 80px);
  overflow-y: auto;
  width: 100%;
  z-index: 2;
}

.mobile-drawer__offside {
  @include animate(opacity);
  background: rgba(0, 0, 0, 0.25);
  height: 100vh;
  opacity: 0;
  pointer-events: none;
  position: fixed;
  right: 0;
  top: 0;
  width: 100vw;
  z-index: 3;

  &.is-visible {
    opacity: 1;
    pointer-events: all;
  }
}

.hamburger-lines {
  appearance: none;
  background: none;
  border: 0 none;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  height: 18px;
  position: relative;
  justify-content: space-between;
  width: 30px;
  z-index: 2;

  .line {
    @include animate(transform, .2s);
    background: $color-white;
    border-radius: 10px;
    display: block;
    height: 2px;
    width: 100%;
  }

  .line1 {
    transform-origin: 60% 0%;
  }

  .line3 {
    transform-origin: 60% 0%;
    // transition: transform 0.4s ease-in-out;
  }

  &:hover {
    .line1 {
      transform: rotate(45deg) scaleX(0.5) translateX(15px) translateY(-2px);
    }

    .line3 {
      transform: rotate(-45deg) scaleX(0.5) translateX(14px) translateY(1px);
    }
  }
}

/* Mobile Menu */
.mobile-menu {
  font-family: "Noto Sans", sans-serif;
  line-height: 1;
  padding: 5px;
}

.mobile-menu__item {
  // font-size: 20px;
}

.mobile-menu__label {
  &,
  &:link,
  &:visited {
    @include animate(all);
    background: $color-white;
    color: $color-black;
    cursor: pointer;
    display: block;
    font-size: 20px;
    height: 20px;
    padding: 5px;
    position: relative;
  }

  &:focus,
  &:hover,
  &.is-open {
    background: $color-brand-blue;
    color: $color-white;
  }

  &.has-children {
    &::before {
      @include animate(transform);
      border-bottom: 2px solid;
      border-right: 2px solid;
      content: '';
      display: block;
      box-sizing: border-box;
      position: absolute;
      height: 10px;
      right: 10px;
      top: calc(50% - 7px);
      transform: rotate(45deg);
      transform-origin: 66% 69%;
      width: 10px;
    }

    &.is-open {
      &::before {
        transform: rotate(225deg);
      }
    }
  }
}

.mobile-menu__children {
  display: block;
  padding-bottom: 5px;
}

.mobile-menu__children-wrapper {
  @include animate(height);
  height: 0;
  overflow: hidden;
}

.mobile-menu__children-item {
  padding: 5px 5px 0 15px;
}

.mobile-menu__children-label {
  &,
  &:link,
  &:visited {
    @include animate(all);
    background: $color-white;
    color: $color-black;
    display: block;
    font-size: 16px;
    padding: 5px;
  }

  &:focus,
  &:hover {
    background: $color-brand-blue;
    color: $color-white;
  }
}

ul {
  &#navBar {
    font: 700 20px/28px 'Noto Sans', sans-serif;
    height: 42px;
    position: relative;
    margin: 0 10px;

    >li {
      float: left;
      margin: 0;
      height: 42px;
      width: auto;
      min-width: 16%;
      text-align: left;

      &.standard {
        position: relative;
      }

      a,
      p.blankLink {
        display: block;
        line-height: 42px;
        color: rgb(255, 255, 255);
        text-decoration: none;
        height: 42px;
        padding: 0 10px;
        text-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
      }

      a {
        &.home i {
          width: 35px;
          height: 40px;
          float: left;
          display: block;
          margin: 1px 10px 1px 0;
          background: url('./assets/images/hdr_logo_40_wht.png') no-repeat;
        }

        &:hover {
          background-color: rgb(16, 146, 195);
          background-color: rgb(0, 60, 128);
          cursor: pointer;
        }
      }

      &:hover {

        a,
        p.blankLink {
          background-color: rgb(16, 146, 195);
          background-color: rgb(0, 60, 128);
          cursor: pointer;
        }
      }
    }

    li {
      ul.tabs {
        position: absolute;
        top: 42px;
        left: 0px;
        width: auto;
        border-radius: 0 0 $border-radius $border-radius;
        background-color: rgb(16, 146, 195);
        background-color: rgb(0, 60, 128);
        z-index: 2;
        box-shadow: 0 3px 2px -1px rgba(0, 0, 0, 0.5);

        &.is-active {
          display: block;
        }
      }

      &.subs ul.tabs li {
        // display: block;
        // float: none;
        // padding: 0;
        margin: 5px;
        height: 26px;
        // width: 178px;
        white-space: nowrap;
        // text-align: left;

        a {
          // background-color: rgb(0,0,0,0) !important;
          // display: block;
          // float: none;
          // font: 700 14px/20px 'Noto Sans', sans-serif;
          // height: 20px;
          // margin: 0;
          // padding: 3px;
          // white-space: nowrap;
        }
      }

      &#steelMenu ul.tabs {
        height: 150px;
        width: 940px;
        column-count: 4;
        -moz-column-count: 4;
        -webkit-column-count: 4;

        li {
          // float: left;
          // width: 190px;

          a {
            // background-color: none !important;
          }
        }
      }

      ul.tabs li a:hover {
        background: rgba(0, 0, 0, 0.15);
      }
    }
  }

  // &#footNav {
  //   float: right;

  //   li {
  //     float: left;

  //     a,
  //     &.copyright {
  //       color: #666;
  //       display: block;
  //       padding: 6px;
  //       height: 18px;
  //       font: 500 12px/18px 'Noto Sans', sans-serif;
  //       text-shadow: 0 1px 0 rgba(255, 255, 255, 0.6);
  //     }

  //     a:hover {
  //       background: rgba(255, 255, 255, 0.8);
  //     }
  //   }
  // }
}
