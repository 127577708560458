.header {
  @include animate(box-shadow);
  box-shadow: 0 0 0 rgba(0, 0, 0, 0);
  position: relative;
  top: 0px;
  left: 0px;
  width: 100%;
  z-index: 2;

  &.is-floating {
    box-shadow: 0 0 8px rgba(0, 0, 0, .5);
  }

  .header-container {
    display: flex;
    justify-content: space-between;
    max-width: $site-width;
    margin: 0 auto;
    padding: 10px 0;
    width: 100%;
  }

  .container {
    max-width: $site-width;
    width: 100%;
  }
}

.header-topper {
  background-color: rgba(255, 255, 255, 1);
  display: block;
  padding: 5px;

  @include media-min-md() {
    padding: 10px 0 20px;
  }

  .container {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;

    @include media-min-md() {
      flex-direction: row;
      justify-content: space-between;
    }
  }

  h1 {
    color: $color-brand-blue;
    font-family: "Christmas Century", "Times New Roman", serif;
    font-size: 22px;
    font-weight: 700;
    line-height: 1;

    @include media-min-sm() {
      font-size: 32px;
    }
  }

  h3 {
    color: $color-brand-blue;
    font-family: 'Noto Sans', sans-serif;
    font-size: 16px;
    font-weight: 700;
    line-height: 1;

    @include media-min-sm() {
      font-size: 22px;
    }

    i {
      font-style: normal;
    }
  }
}

.header-comms {
  display: flex;
  flex-direction: row;
  padding-top: 5px;
  color: $color-brand-blue;
  text-align: right;
  width: 100%;

  @include media-min-md() {
    flex-direction: column;
    width: 180px;
  }

  @include media-max-md() {
    justify-content: space-around;
  }

  .telComms {
    display: flex;
    font: 700 20px/32px 'Noto Sans', sans-serif;
    padding: 5px 0;

    @include media-min-md() {
      // flex-direction: column;
    }

    span {
      float: right;
      display: block;
      height: 32px;

      &.tele,
      &.fax {
        width: 32px;
        margin-right: 10px;
      }

      &.tele {
        background: url('/images/telIconBlu.png') no-repeat;
      }

      &.fax {
        background: url('/images/faxIconBlu.png') no-repeat;
      }
    }
  }
}

.logo-wrapper {
  display: flex;
  max-width: 720px;
}

.header-text {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.header-logo {
  background-image: url('/images/hdr_logo_100.png');
  background-repeat: no-repeat;
  background-size: contain;
  display: block;
  flex: 1 0 53px;
  height: 60px;
  margin-right: 10px;
  width: 87px;

  @include media-min-sm() {
    height: 100px;
    width: 87px;
  }
}
