// COLOURS
$color-white: #fff;
$color-black: #000;
$color-brand-blue: #0063D3;
$color-brand-blue-hover: rgba(0, 60, 128, 1);

//
$border-radius: 4px;

// ANIMATIONS
$easing: cubic-bezier(.4, 0, .2, 1);
$animation-speed: 250ms;
$interaction-percent: 5%;

$site-width: 1120px;

// Font Size
$fs-normal: 16px;

$fs-hdr-1: 38px;
$fs-hdr-2: 32px;
$fs-hdr-3: 26px;
$fs-hdr-4: 20px;

$fs-multi: .8;
