@font-face {
  font-family: "Noto Sans";
  font-style: normal;
  font-weight: 100;
  src: local("Noto Sans Thin"), local("Noto Sans Thin"), url("/assets/fonts/NotoSans-Thin.woff") format("woff");
}
@font-face {
  font-family: "Noto Sans";
  font-style: normal;
  font-weight: 400;
  src: local("Noto Sans Medium"), local("Noto Sans Medium"), url("/assets/fonts/NotoSans-Medium.woff") format("woff");
}
@font-face {
  font-family: "Noto Sans";
  font-style: normal;
  font-weight: 500;
  src: local("Noto Sans Regular"), local("Noto Sans Regular"), url("/assets/fonts/NotoSans-Regular.woff") format("woff");
}
@font-face {
  font-family: "Noto Sans";
  font-style: normal;
  font-weight: 700;
  src: local("Noto Sans Bold"), local("Noto Sans Bold"), url("/assets/fonts/NotoSans-Bold.woff") format("woff");
}
@font-face {
  font-family: "Christmas Century";
  font-style: normal;
  font-weight: 400;
  src: local("Christmas Century"), local("Christmas Century"), url("/assets/fonts/NewCenturySchlbkRoman.woff") format("woff");
}
@font-face {
  font-family: "Christmas Century";
  font-style: normal;
  font-weight: 700;
  src: local("Christmas Century Bold"), local("Christmas Century Bold"), url("/assets/fonts/NewCenturySchlbkBold.woff") format("woff");
}
/* stylelint-disable media-feature-name-no-unknown */
/* stylelint-enable media-feature-name-no-unknown */
/** Light color-scheme **/
:root {
  --cc-bg: #fff;
  --cc-text: #2d4156;
  --cc-border-radius: 4px;
  --cc-btn-primary-bg: #0063D3;
  --cc-btn-primary-text: var(--cc-bg);
  --cc-btn-primary-hover-bg: rgb(0, 60, 128);
  --cc-btn-secondary-bg: #eaeff2;
  --cc-btn-secondary-text: var(--cc-text);
  --cc-btn-secondary-hover-bg: #d8e0e6;
  --cc-btn-border-radius: 4px;
  --cc-toggle-bg-off: #919ea6;
  --cc-toggle-bg-on: var(--cc-btn-primary-bg);
  --cc-toggle-bg-readonly: #d5dee2;
  --cc-toggle-knob-bg: #fff;
  --cc-toggle-knob-icon-color: #ecf2fa;
  --cc-block-text: var(--cc-text);
  --cc-cookie-category-block-bg: #f0f4f7;
  --cc-cookie-category-block-bg-hover: #e9eff4;
  --cc-section-border: #f1f3f5;
  --cc-cookie-table-border: #e9edf2;
  --cc-overlay-bg: rgba(4, 6, 8, .85);
  --cc-webkit-scrollbar-bg: #cfd5db;
  --cc-webkit-scrollbar-bg-hover: #9199a0;
}

.cookie-logo-header {
  align-items: center;
  display: flex;
}
.cookie-logo-header .cookie-logo-header__text, .cookie-logo-header .cookie-logo-header__text:hover {
  align-items: center;
  color: #0063D3;
  display: inline-flex;
  font-family: "Christmas Century", "Times New Roman", serif;
  font-size: 22px;
  font-weight: 700;
  height: 35px;
  line-height: 1;
}

.cc_div *,
.cc_div *:hover,
.cc_div :before,
.cc_div :after {
  box-sizing: border-box;
  float: none;
  font-style: inherit;
  font-variant: normal;
  font-weight: inherit;
  font-family: inherit;
  line-height: 1.2;
  font-size: 1em;
  transition: none;
  animation: none;
  margin: 0;
  padding: 0;
  text-transform: none;
  letter-spacing: unset;
  color: inherit;
  background: none;
  border: none;
  border-radius: unset;
  box-shadow: none;
  text-decoration: none;
  text-align: left;
  visibility: unset;
  height: auto;
  vertical-align: baseline;
}

.cc_div {
  font-size: 16px;
  font-weight: 400;
  font-family: "Noto Sans", sans-serif, Helvetica, Arial, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  color: var(--cc-text);
}

.cc_div .c-bn,
.cc_div .b-tl,
#s-ttl,
#c-ttl,
#s-bl td:before {
  font-weight: 600;
}

#cm,
#s-inr,
.cc_div .c-bl,
.cc_div .b-tl,
#s-bl .act .b-acc {
  border-radius: var(--cc-border-radius);
}

#s-bl .act .b-acc {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.cc_div input,
.cc_div button,
.cc_div a {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  overflow: hidden;
}

.cc_div a {
  border-bottom: 1px solid;
}

.cc_div a:hover {
  text-decoration: none;
  border-color: transparent;
}

/* Make elements "animatable" */
.c--anim #cm,
.c--anim #s-cnt,
.c--anim #s-inr,
#cs-ov,
#cm-ov {
  transition: visibility 0.25s linear, opacity 0.25s ease, transform 0.25s ease !important;
}

.c--anim .c-bn {
  transition: background-color 0.25s ease !important;
}

/* start transitions */
.c--anim #cm.bar.slide,
.c--anim .bar.slide #s-inr {
  transition: visibility 0.4s ease, opacity 0.4s ease, transform 0.4s ease !important;
}

.c--anim #cm.bar.slide + #cm-ov,
.c--anim .bar.slide + #cs-ov {
  transition: visibility 0.4s ease, opacity 0.4s ease, transform 0.4s ease !important;
}

#cm.bar.slide,
.cc_div .bar.slide #s-inr {
  transform: translateX(100%);
  opacity: 1;
}

#cm.bar.top.slide,
.cc_div .bar.left.slide #s-inr {
  transform: translateX(-100%);
  opacity: 1;
}

#cm.slide,
.cc_div .slide #s-inr {
  transform: translateY(1.6em);
}

#cm.top.slide {
  transform: translateY(-1.6em);
}

#cm.bar.slide {
  transform: translateY(100%);
}

#cm.bar.top.slide {
  transform: translateY(-100%);
}

/* end transitions */
/* Show modals */
.show--consent .c--anim #cm,
.show--consent .c--anim #cm.bar,
.show--settings .c--anim #s-inr,
.show--settings .c--anim .bar.slide #s-inr {
  opacity: 1;
  transform: scale(1);
  visibility: visible !important;
}

.show--consent .c--anim #cm.box.middle,
.show--consent .c--anim #cm.cloud.middle {
  transform: scale(1) translateY(-50%);
}

.show--settings .c--anim #s-cnt {
  visibility: visible !important;
}

/* Show overlays */
.force--consent.show--consent .c--anim #cm-ov,
.show--settings .c--anim #cs-ov {
  visibility: visible !important;
  opacity: 1 !important;
}

#cm {
  font-family: inherit;
  padding: 1.1em 1.5em 1.4em 1.5em;
  position: fixed;
  z-index: 1;
  background: var(--cc-bg);
  max-width: 24.2em;
  width: 100%;
  bottom: 1.25em;
  right: 1.25em;
  box-shadow: 0 0.625em 1.875em #000000;
  box-shadow: 0 0.625em 1.875em rgba(2, 2, 3, 0.28);
  opacity: 0;
  visibility: hidden;
  transform: scale(0.95);
  line-height: initial;
}

/** fix https://github.com/orestbida/cookieconsent/issues/94 **/
#cc_div #cm {
  display: block !important;
}

#c-ttl {
  margin-bottom: 0.7em;
  font-size: 1.05em;
}

.cloud #c-ttl {
  margin-top: -0.15em;
}

#c-txt {
  font-size: 0.9em;
  line-height: 1.5em;
}

.cc_div #c-bns {
  display: flex;
  justify-content: space-between;
  margin-top: 1.4em;
}

.cc_div .c-bn {
  color: var(--cc-btn-secondary-text);
  background: var(--cc-btn-secondary-bg);
  padding: 1em 1.7em;
  display: inline-block;
  cursor: pointer;
  font-size: 0.82em;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -o-user-select: none;
  user-select: none;
  text-align: center;
  border-radius: var(--cc-btn-border-radius);
  flex: 1;
}

#c-bns button + button,
#s-cnt button + button,
#s-c-bn {
  float: right;
  margin-left: 1em;
}

#s-cnt #s-rall-bn {
  float: none;
}

#cm .c_link:hover,
#cm .c_link:active,
#s-cnt button + button:hover,
#s-cnt button + button:active,
#s-c-bn:active,
#s-c-bn:hover {
  background: var(--cc-btn-secondary-hover-bg);
}

/**
CookieConsent settings modal
**/
#s-cnt {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 101;
  display: table;
  height: 100%;
  visibility: hidden;
}

#s-bl {
  outline: none;
}

#s-bl .title {
  margin-top: 1.4em;
}

#s-bl .title:first-child {
  margin-top: 0;
}

#s-bl .b-bn {
  margin-top: 0;
}

#s-bl .b-acc .p {
  margin-top: 0;
  padding: 1em;
}

#s-cnt .b-bn .b-tl {
  display: block;
  font-family: inherit;
  font-size: 0.95em;
  width: 100%;
  position: relative;
  padding: 1.3em 6.4em 1.3em 2.7em;
  background: none;
  transition: background-color 0.25s ease;
}

#s-cnt .b-bn .b-tl.exp {
  cursor: pointer;
}

#s-cnt .act .b-bn .b-tl {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

#s-cnt .b-bn .b-tl:active,
#s-cnt .b-bn .b-tl:hover {
  background: var(--cc-cookie-category-block-bg-hover);
}

#s-bl .b-bn {
  position: relative;
}

#s-bl .c-bl {
  padding: 1em;
  margin-bottom: 0.5em;
  border: 1px solid var(--cc-section-border);
  transition: background-color 0.25s ease;
}

#s-bl .c-bl:hover {
  background: var(--cc-cookie-category-block-bg);
}

#s-bl .c-bl:last-child {
  margin-bottom: 0.5em;
}

#s-bl .c-bl:first-child {
  transition: none;
  padding: 0;
  margin-top: 0;
  border: none;
  margin-bottom: 2em;
}

#s-bl .c-bl:not(.b-ex):first-child:hover {
  background: transparent;
  background: unset;
}

#s-bl .c-bl.b-ex {
  padding: 0;
  border: none;
  background: var(--cc-cookie-category-block-bg);
  transition: none;
}

#s-bl .c-bl.b-ex + .c-bl {
  margin-top: 2em;
}

#s-bl .c-bl.b-ex + .c-bl.b-ex {
  margin-top: 0;
}

#s-bl .c-bl.b-ex:first-child {
  margin-bottom: 1em;
}

#s-bl .c-bl.b-ex:first-child {
  margin-bottom: 0.5em;
}

#s-bl .b-acc {
  max-height: 0;
  overflow: hidden;
  padding-top: 0;
  margin-bottom: 0;
  display: none;
}

#s-bl .act .b-acc {
  max-height: 100%;
  display: block;
  overflow: hidden;
}

#s-cnt .p {
  font-size: 0.9em;
  line-height: 1.5em;
  margin-top: 0.85em;
  color: var(--cc-block-text);
}

.cc_div .b-tg .c-tgl:disabled {
  cursor: not-allowed;
}

#c-vln {
  display: table-cell;
  vertical-align: middle;
  position: relative;
}

#cs {
  padding: 0 1.7em;
  width: 100%;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  height: 100%;
}

#s-inr {
  height: 100%;
  position: relative;
  max-width: 45em;
  margin: 0 auto;
  transform: scale(0.96);
  opacity: 0;
  padding-top: 4.75em;
  padding-bottom: 4.75em;
  position: relative;
  height: 100%;
  overflow: hidden;
  visibility: hidden;
  box-shadow: rgba(3, 6, 9, 0.26) 0px 13px 27px -5px;
}

#s-inr,
#s-hdr,
#s-bns {
  background: var(--cc-bg);
}

#s-bl {
  overflow-y: auto;
  overflow-y: overlay;
  overflow-x: hidden;
  height: 100%;
  padding: 1.3em 1.8em;
  display: block;
  width: 100%;
}

#s-bns {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 1em 1.8em;
  border-top: 1px solid var(--cc-section-border);
  height: 4.75em;
}

.cc_div .cc-link {
  color: var(--cc-btn-primary-bg);
  border-bottom: 1px solid var(--cc-btn-primary-bg);
  display: inline;
  padding-bottom: 0;
  text-decoration: none;
  cursor: pointer;
  font-weight: 600;
}

.cc_div .cc-link:hover,
.cc_div .cc-link:active {
  border-color: transparent;
}

#c-bns button:first-child,
#s-bns button:first-child {
  color: var(--cc-btn-primary-text);
  background: var(--cc-btn-primary-bg);
}

#c-bns.swap button:first-child {
  color: var(--cc-btn-secondary-text);
  background: var(--cc-btn-secondary-bg);
}

#c-bns.swap button:last-child {
  color: var(--cc-btn-primary-text);
  background: var(--cc-btn-primary-bg);
}

.cc_div .b-tg .c-tgl:checked ~ .c-tg {
  background: var(--cc-toggle-bg-on);
}

#c-bns button:first-child:active,
#c-bns button:first-child:hover,
#s-bns button:first-child:active,
#s-bns button:first-child:hover,
#c-bns.swap button:last-child:active,
#c-bns.swap button:last-child:hover {
  background: var(--cc-btn-primary-hover-bg);
}

#c-bns.swap button:first-child:active,
#c-bns.swap button:first-child:hover {
  background: var(--cc-btn-secondary-hover-bg);
}

#s-hdr {
  position: absolute;
  top: 0;
  width: 100%;
  display: table;
  padding: 0 1.8em;
  height: 4.75em;
  vertical-align: middle;
  z-index: 2;
  border-bottom: 1px solid var(--cc-section-border);
}

#s-ttl {
  display: table-cell;
  vertical-align: middle;
  font-size: 1em;
}

#s-c-bn {
  padding: 0;
  width: 1.7em;
  height: 1.7em;
  font-size: 1.45em;
  margin: 0;
  font-weight: initial;
  position: relative;
  overflow: hidden;
}

#s-c-bnc {
  display: table-cell;
  vertical-align: middle;
}

.cc_div span.t-lb {
  position: absolute;
  top: 0;
  z-index: -1;
  opacity: 0;
  pointer-events: none;
  overflow: hidden;
}

#c_policy__text {
  height: 31.25em;
  overflow-y: auto;
  margin-top: 1.25em;
}

#c-s-in {
  position: relative;
  transform: translateY(-50%);
  top: 50%;
  height: 100%;
  height: calc(100% - 2.5em);
  max-height: 32em;
}

@media screen and (min-width: 688px) {
  /** works only on webkit-based browsers **/
  #s-bl::-webkit-scrollbar {
    width: 0.9em;
    height: 100%;
    background: transparent;
    border-radius: 0 0.25em 0.25em 0;
  }
  #s-bl::-webkit-scrollbar-thumb {
    border: 0.25em solid var(--cc-bg);
    background: var(--cc-webkit-scrollbar-bg);
    border-radius: 100em;
  }
  #s-bl::-webkit-scrollbar-thumb:hover {
    background: var(--cc-webkit-scrollbar-bg-hover);
  }
  #s-bl::-webkit-scrollbar-button {
    width: 10px;
    height: 5px;
  }
}
/** custom checkbox **/
/* The container */
.cc_div .b-tg {
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  display: inline-block;
  margin: auto;
  right: 1.2em;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  vertical-align: middle;
}

/* Hide the browser's default checkbox */
.cc_div .b-tg .c-tgl {
  position: absolute;
  cursor: pointer;
  display: block;
  top: 0;
  left: 0;
  margin: 0;
  border: 0;
}

/* Create a custom checkbox */
.cc_div .b-tg .c-tg {
  position: absolute;
  background: var(--cc-toggle-bg-off);
  transition: background-color 0.25s ease, box-shadow 0.25s ease;
  pointer-events: none;
}

.cc_div span.t-lb,
.cc_div .b-tg,
.cc_div .b-tg .c-tg,
.cc_div .b-tg .c-tgl {
  width: 3.4em;
  height: 1.5em;
  border-radius: 4em;
}

.cc_div .b-tg .c-tg.c-ro {
  cursor: not-allowed;
}

.cc_div .b-tg .c-tgl ~ .c-tg.c-ro {
  background: var(--cc-toggle-bg-readonly);
}

.cc_div .b-tg .c-tgl ~ .c-tg.c-ro:after {
  box-shadow: none;
}

/* Style the checkmark/indicator */
.cc_div .b-tg .c-tg:after {
  content: "";
  position: relative;
  display: block;
  left: 0.125em;
  top: 0.125em;
  width: 1.25em;
  height: 1.25em;
  border: none;
  box-sizing: content-box;
  background: var(--cc-toggle-knob-bg);
  box-shadow: 0 1px 2px rgba(24, 32, 35, 0.36);
  transition: transform 0.25s ease;
  border-radius: 100%;
}

/* Show the checkmark when checked */
.cc_div .b-tg .c-tgl:checked ~ .c-tg:after {
  transform: translateX(1.9em);
}

#s-bl table,
#s-bl th,
#s-bl td {
  border: none;
}

#s-bl tbody tr {
  transition: background-color 0.25s ease;
}

#s-bl tbody tr:hover {
  background: var(--cc-cookie-category-block-bg-hover);
}

#s-bl table {
  text-align: left;
  border-collapse: collapse;
  width: 100%;
  padding: 0;
  margin: 0;
  overflow: hidden;
}

#s-bl td,
#s-bl th {
  padding: 0.8em 0.625em;
  text-align: left;
  vertical-align: top;
  font-size: 0.8em;
  padding-left: 1.2em;
}

#s-bl th {
  font-family: inherit;
  padding: 1.2em 1.2em;
}

#s-bl thead tr:first-child {
  border-bottom: 1px solid var(--cc-cookie-table-border);
}

.force--consent #s-cnt,
.force--consent #cs {
  width: 100vw;
}

#cm-ov,
#cs-ov {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  visibility: hidden;
  opacity: 0;
  background: #070707;
  background: var(--cc-overlay-bg);
  display: none;
  transition: none;
}

.show--settings #cs-ov,
.c--anim #cs-ov,
.force--consent .c--anim #cm-ov,
.force--consent.show--consent #cm-ov {
  display: block;
}

#cs-ov {
  z-index: 2;
}

.force--consent .cc_div {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  width: 100vw;
  visibility: hidden;
  transition: visibility 0.25s linear;
}

.force--consent.show--consent .c--anim .cc_div,
.force--consent.show--settings .c--anim .cc_div {
  visibility: visible;
}

.force--consent #cm {
  position: absolute;
}

.force--consent #cm.bar {
  width: 100vw;
  max-width: 100vw;
}

html.force--consent.show--consent {
  overflow-y: hidden !important;
}

html.force--consent.show--consent,
html.force--consent.show--consent body {
  height: auto !important;
  overflow-x: hidden !important;
}

/** END BLOCK PAGE SCROLL */
/** BEGIN ICONS **/
.cc_div .b-bn .exp::before,
.cc_div .act .b-bn .exp::before {
  border: solid var(--cc-btn-secondary-text);
  border-width: 0 2px 2px 0;
  padding: 0.2em;
  display: inline-block;
  position: absolute;
  content: "";
  margin-right: 15px;
  position: absolute;
  transform: translateY(-50%) rotate(45deg);
  left: 1.2em;
  top: 50%;
}

.cc_div .act .b-bn .b-tl::before {
  transform: translateY(-20%) rotate(225deg);
}

.cc_div .on-i::before {
  border: solid var(--cc-toggle-knob-icon-color);
  border-width: 0 2px 2px 0;
  padding: 0.1em;
  display: inline-block;
  padding-bottom: 0.45em;
  content: "";
  margin: 0 auto;
  transform: rotate(45deg);
  top: 0.37em;
  left: 0.75em;
  position: absolute;
}

#s-c-bn::before,
#s-c-bn::after {
  content: "";
  position: absolute;
  left: 0.82em;
  top: 0.58em;
  height: 0.6em;
  width: 1.5px;
  background: var(--cc-btn-secondary-text);
  transform: rotate(45deg);
  border-radius: 1em;
  margin: 0 auto;
}

#s-c-bn::after {
  transform: rotate(-45deg);
}

.cc_div .off-i,
.cc_div .on-i {
  height: 100%;
  width: 50%;
  position: absolute;
  right: 0;
  display: block;
  text-align: center;
  transition: opacity 0.15s ease;
}

.cc_div .on-i {
  left: 0;
  opacity: 0;
}

.cc_div .off-i::before,
.cc_div .off-i::after {
  right: 0.8em;
  top: 0.42em;
  content: " ";
  height: 0.7em;
  width: 0.09375em;
  display: block;
  background: var(--cc-toggle-knob-icon-color);
  margin: 0 auto;
  position: absolute;
  transform-origin: center;
}

.cc_div .off-i::before {
  transform: rotate(45deg);
}

.cc_div .off-i::after {
  transform: rotate(-45deg);
}

.cc_div .b-tg .c-tgl:checked ~ .c-tg .on-i {
  opacity: 1;
}

.cc_div .b-tg .c-tgl:checked ~ .c-tg .off-i {
  opacity: 0;
}

/** END ICONS **/
#cm.box.middle,
#cm.cloud.middle {
  top: 50%;
  transform: translateY(-37%);
  bottom: auto;
}

#cm.box.middle.zoom,
#cm.cloud.middle.zoom {
  transform: scale(0.95) translateY(-50%);
}

#cm.box.center {
  left: 1em;
  right: 1em;
  margin: 0 auto;
}

/* Start cloud layout */
#cm.cloud {
  max-width: 50em;
  margin: 0 auto;
  text-align: center;
  left: 1em;
  right: 1em;
  overflow: hidden;
  padding: 1.3em 2em;
  width: unset;
}

.cc_div .cloud #c-inr {
  display: table;
  width: 100%;
}

.cc_div .cloud #c-inr-i {
  width: 70%;
  display: table-cell;
  vertical-align: top;
  padding-right: 2.4em;
}

.cc_div .cloud #c-txt {
  font-size: 0.85em;
}

.cc_div .cloud #c-bns {
  min-width: 170px;
  display: table-cell;
  vertical-align: middle;
}

#cm.cloud .c-bn {
  margin: 0.625em 0 0 0;
  width: 100%;
}

#cm.cloud .c-bn:first-child {
  margin: 0;
}

#cm.cloud.left {
  margin-right: 1.25em;
}

#cm.cloud.right {
  margin-left: 1.25em;
}

/* End cloud layout */
/* Start bar layout */
#cm.bar {
  width: 100%;
  max-width: 100%;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 0;
  position: fixed;
  padding: 2em;
}

#cm.bar #c-inr {
  max-width: 32em;
  margin: 0 auto;
}

#cm.bar #c-bns {
  max-width: 33.75em;
}

#cm.bar #cs {
  padding: 0;
}

.cc_div .bar #c-s-in {
  top: 0;
  transform: none;
  height: 100%;
  max-height: 100%;
}

.cc_div .bar #s-hdr,
.cc_div .bar #s-bl,
.cc_div .bar #s-bns {
  padding-left: 1.6em;
  padding-right: 1.6em;
}

.cc_div .bar #cs {
  padding: 0;
}

/* align bar to right by default */
.cc_div .bar #s-inr {
  margin: 0;
  margin-left: auto;
  margin-right: 0;
  border-radius: 0;
  max-width: 32em;
}

.cc_div .bar.left #s-inr {
  margin-left: 0;
  margin-right: auto;
}

/* Force table to not be like tables anymore */
.cc_div .bar #s-bl table,
.cc_div .bar #s-bl thead,
.cc_div .bar #s-bl tbody,
.cc_div .bar #s-bl th,
.cc_div .bar #s-bl td,
.cc_div .bar #s-bl tr,
.cc_div .bar #s-cnt {
  display: block;
}

/* Hide table headers (but not display: none;, for accessibility) */
.cc_div .bar #s-bl thead tr {
  position: absolute;
  top: -9999px;
  left: -9999px;
}

.cc_div .bar #s-bl tr {
  border-top: 1px solid var(--cc-cookie-table-border);
}

.cc_div .bar #s-bl td {
  /* Behave  like a "row" */
  border: none;
  position: relative;
  padding-left: 35%;
}

.cc_div .bar #s-bl td:before {
  position: absolute;
  left: 1em;
  padding-right: 0.625em;
  white-space: nowrap;
  content: attr(data-column);
  color: var(--cc-text);
  overflow: hidden;
  text-overflow: ellipsis;
}

/* End bar layout */
/* Positions */
#cm.top {
  bottom: auto;
  top: 1.25em;
}

#cm.left {
  right: auto;
  left: 1.25em;
}

#cm.right {
  left: auto;
  right: 1.25em;
}

#cm.bar.left,
#cm.bar.right {
  left: 0;
  right: 0;
}

#cm.bar.top {
  top: 0;
}

/* end positions */
@media screen and (max-width: 688px) {
  #cm,
  #cm.cloud,
  #cm.left,
  #cm.right {
    width: auto;
    max-width: 100%;
    margin: 0;
    padding: 1.4em !important;
    right: 1em;
    left: 1em;
    bottom: 1em;
    display: block;
  }
  .force--consent #cm,
  .force--consent #cm.cloud {
    width: auto;
    max-width: 100vw;
  }
  #cm.top {
    top: 1em;
    bottom: auto;
  }
  #cm.bottom {
    bottom: 1em;
    top: auto;
  }
  #cm.bar.bottom {
    bottom: 0;
  }
  #cm.cloud .c-bn {
    font-size: 0.85em;
  }
  #s-bns,
  .cc_div .bar #s-bns {
    padding: 1em 1.3em;
  }
  .cc_div .bar #s-inr {
    max-width: 100%;
    width: 100%;
  }
  .cc_div .cloud #c-inr-i {
    padding-right: 0;
  }
  #cs {
    border-radius: 0;
    padding: 0;
  }
  #c-s-in {
    max-height: 100%;
    height: 100%;
    top: 0;
    transform: none;
  }
  .cc_div .b-tg {
    transform: scale(1.1);
    right: 1.1em;
  }
  #s-inr {
    margin: 0;
    padding-bottom: 7.9em;
    border-radius: 0;
  }
  #s-bns {
    height: 7.9em;
  }
  #s-bl,
  .cc_div .bar #s-bl {
    padding: 1.3em;
  }
  #s-hdr,
  .cc_div .bar #s-hdr {
    padding: 0 1.3em;
  }
  /** dynamic table layout **/
  #s-bl table {
    width: 100%;
  }
  #s-inr.bns-t {
    padding-bottom: 10.5em;
  }
  .bns-t #s-bns {
    height: 10.5em;
  }
  .cc_div .bns-t .c-bn {
    font-size: 0.83em;
    padding: 0.9em 1.6em;
  }
  #s-cnt .b-bn .b-tl {
    padding-top: 1.2em;
    padding-bottom: 1.2em;
  }
  /* Force table to not be like tables anymore */
  #s-bl table,
  #s-bl thead,
  #s-bl tbody,
  #s-bl th,
  #s-bl td,
  #s-bl tr,
  #s-cnt {
    display: block;
  }
  /* Hide table headers (but not display: none;, for accessibility) */
  #s-bl thead tr {
    position: absolute;
    top: -9999px;
    left: -9999px;
  }
  #s-bl tr {
    border-top: 1px solid var(--cc-cookie-table-border);
  }
  #s-bl td {
    /* Behave  like a "row" */
    border: none;
    position: relative;
    padding-left: 35%;
  }
  #s-bl td:before {
    position: absolute;
    left: 1em;
    padding-right: 0.625em;
    white-space: nowrap;
    content: attr(data-column);
    color: var(--cc-text);
    overflow: hidden;
    text-overflow: ellipsis;
  }
  #cm .c-bn,
  .cc_div .c-bn {
    width: 100%;
    margin-right: 0;
  }
  #s-cnt #s-rall-bn {
    margin-left: 0;
  }
  .cc_div #c-bns {
    flex-direction: column;
  }
  #c-bns button + button,
  #s-cnt button + button {
    margin-top: 0.625em;
    margin-left: 0;
    float: unset;
  }
  #cm.cloud,
  #cm.box {
    left: 1em;
    right: 1em;
    width: auto;
  }
  #cm.cloud.right,
  #cm.cloud.left {
    margin: 0;
  }
  .cc_div .cloud #c-bns,
  .cc_div .cloud #c-inr,
  .cc_div .cloud #c-inr-i {
    display: block;
    width: auto;
    min-width: unset;
  }
  .cc_div .cloud #c-txt {
    font-size: 0.9em;
  }
  .cc_div .cloud #c-bns {
    margin-top: 1.625em;
  }
}
/* Begin IE fixes */
.cc_div.ie #c-vln {
  height: 100%;
  padding-top: 5.62em;
}

.cc_div.ie .bar #c-vln {
  padding-top: 0;
}

.cc_div.ie #cs {
  max-height: 37.5em;
  position: relative;
  top: 0;
  margin-top: -5.625em;
}

.cc_div.ie .bar #cs {
  margin-top: 0;
  max-height: 100%;
}

.cc_div.ie #cm {
  border: 1px solid #dee6e9;
}

.cc_div.ie #c-s-in {
  top: 0;
}

.cc_div.ie .b-tg {
  padding-left: 1em;
  margin-bottom: 0.7em;
}

.cc_div.ie .b-tg .c-tgl:checked ~ .c-tg:after {
  left: 1.95em;
}

.cc_div.ie #s-bl table {
  overflow: auto;
}

.cc_div.ie .b-tg .c-tg {
  display: none;
}

.cc_div.ie .b-tg .c-tgl {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  margin-bottom: 0.2em;
  height: auto;
}

.cc_div.ie #s-cnt .b-bn .b-tl {
  padding: 1.3em 6.4em 1.3em 1.4em;
}

.cc_div.ie .bar #s-bl td:before {
  display: none;
}

.cc_div.ie .bar #s-bl td {
  padding: 0.8em 0.625em 0.8em 1.2em;
}

.cc_div.ie .bar #s-bl thead tr {
  position: relative;
}

.cc_div.ie .b-tg .t-lb {
  filter: alpha(opacity=0);
}

.cc_div.ie #cm-ov,
.cc_div.ie #cs-ov {
  filter: alpha(opacity=80);
}

/** END IE FIXES **/
.m-0 {
  margin: 0px !important;
}

.p-0 {
  padding: 0px !important;
}

.mx-0 {
  margin-left: 0px !important;
  margin-right: 0px !important;
}

.my-0 {
  margin-bottom: 0px !important;
  margin-top: 0px !important;
}

.px-0 {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.py-0 {
  padding-bottom: 0px !important;
  padding-top: 0px !important;
}

.mt-0 {
  margin-top: 0px !important;
}

.pt-0 {
  padding-top: 0px !important;
}

.mr-0 {
  margin-right: 0px !important;
}

.pr-0 {
  padding-right: 0px !important;
}

.mb-0 {
  margin-bottom: 0px !important;
}

.pb-0 {
  padding-bottom: 0px !important;
}

.ml-0 {
  margin-left: 0px !important;
}

.pl-0 {
  padding-left: 0px !important;
}

.m-1 {
  margin: 4px !important;
}

.p-1 {
  padding: 4px !important;
}

.mx-1 {
  margin-left: 4px !important;
  margin-right: 4px !important;
}

.my-1 {
  margin-bottom: 4px !important;
  margin-top: 4px !important;
}

.px-1 {
  padding-left: 4px !important;
  padding-right: 4px !important;
}

.py-1 {
  padding-bottom: 4px !important;
  padding-top: 4px !important;
}

.mt-1 {
  margin-top: 4px !important;
}

.pt-1 {
  padding-top: 4px !important;
}

.mr-1 {
  margin-right: 4px !important;
}

.pr-1 {
  padding-right: 4px !important;
}

.mb-1 {
  margin-bottom: 4px !important;
}

.pb-1 {
  padding-bottom: 4px !important;
}

.ml-1 {
  margin-left: 4px !important;
}

.pl-1 {
  padding-left: 4px !important;
}

.m-2 {
  margin: 8px !important;
}

.p-2 {
  padding: 8px !important;
}

.mx-2 {
  margin-left: 8px !important;
  margin-right: 8px !important;
}

.my-2 {
  margin-bottom: 8px !important;
  margin-top: 8px !important;
}

.px-2 {
  padding-left: 8px !important;
  padding-right: 8px !important;
}

.py-2 {
  padding-bottom: 8px !important;
  padding-top: 8px !important;
}

.mt-2 {
  margin-top: 8px !important;
}

.pt-2 {
  padding-top: 8px !important;
}

.mr-2 {
  margin-right: 8px !important;
}

.pr-2 {
  padding-right: 8px !important;
}

.mb-2 {
  margin-bottom: 8px !important;
}

.pb-2 {
  padding-bottom: 8px !important;
}

.ml-2 {
  margin-left: 8px !important;
}

.pl-2 {
  padding-left: 8px !important;
}

.m-3 {
  margin: 12px !important;
}

.p-3 {
  padding: 12px !important;
}

.mx-3 {
  margin-left: 12px !important;
  margin-right: 12px !important;
}

.my-3 {
  margin-bottom: 12px !important;
  margin-top: 12px !important;
}

.px-3 {
  padding-left: 12px !important;
  padding-right: 12px !important;
}

.py-3 {
  padding-bottom: 12px !important;
  padding-top: 12px !important;
}

.mt-3 {
  margin-top: 12px !important;
}

.pt-3 {
  padding-top: 12px !important;
}

.mr-3 {
  margin-right: 12px !important;
}

.pr-3 {
  padding-right: 12px !important;
}

.mb-3 {
  margin-bottom: 12px !important;
}

.pb-3 {
  padding-bottom: 12px !important;
}

.ml-3 {
  margin-left: 12px !important;
}

.pl-3 {
  padding-left: 12px !important;
}

.m-4 {
  margin: 16px !important;
}

.p-4 {
  padding: 16px !important;
}

.mx-4 {
  margin-left: 16px !important;
  margin-right: 16px !important;
}

.my-4 {
  margin-bottom: 16px !important;
  margin-top: 16px !important;
}

.px-4 {
  padding-left: 16px !important;
  padding-right: 16px !important;
}

.py-4 {
  padding-bottom: 16px !important;
  padding-top: 16px !important;
}

.mt-4 {
  margin-top: 16px !important;
}

.pt-4 {
  padding-top: 16px !important;
}

.mr-4 {
  margin-right: 16px !important;
}

.pr-4 {
  padding-right: 16px !important;
}

.mb-4 {
  margin-bottom: 16px !important;
}

.pb-4 {
  padding-bottom: 16px !important;
}

.ml-4 {
  margin-left: 16px !important;
}

.pl-4 {
  padding-left: 16px !important;
}

.m-5 {
  margin: 20px !important;
}

.p-5 {
  padding: 20px !important;
}

.mx-5 {
  margin-left: 20px !important;
  margin-right: 20px !important;
}

.my-5 {
  margin-bottom: 20px !important;
  margin-top: 20px !important;
}

.px-5 {
  padding-left: 20px !important;
  padding-right: 20px !important;
}

.py-5 {
  padding-bottom: 20px !important;
  padding-top: 20px !important;
}

.mt-5 {
  margin-top: 20px !important;
}

.pt-5 {
  padding-top: 20px !important;
}

.mr-5 {
  margin-right: 20px !important;
}

.pr-5 {
  padding-right: 20px !important;
}

.mb-5 {
  margin-bottom: 20px !important;
}

.pb-5 {
  padding-bottom: 20px !important;
}

.ml-5 {
  margin-left: 20px !important;
}

.pl-5 {
  padding-left: 20px !important;
}

.m-6 {
  margin: 24px !important;
}

.p-6 {
  padding: 24px !important;
}

.mx-6 {
  margin-left: 24px !important;
  margin-right: 24px !important;
}

.my-6 {
  margin-bottom: 24px !important;
  margin-top: 24px !important;
}

.px-6 {
  padding-left: 24px !important;
  padding-right: 24px !important;
}

.py-6 {
  padding-bottom: 24px !important;
  padding-top: 24px !important;
}

.mt-6 {
  margin-top: 24px !important;
}

.pt-6 {
  padding-top: 24px !important;
}

.mr-6 {
  margin-right: 24px !important;
}

.pr-6 {
  padding-right: 24px !important;
}

.mb-6 {
  margin-bottom: 24px !important;
}

.pb-6 {
  padding-bottom: 24px !important;
}

.ml-6 {
  margin-left: 24px !important;
}

.pl-6 {
  padding-left: 24px !important;
}

.m-7 {
  margin: 28px !important;
}

.p-7 {
  padding: 28px !important;
}

.mx-7 {
  margin-left: 28px !important;
  margin-right: 28px !important;
}

.my-7 {
  margin-bottom: 28px !important;
  margin-top: 28px !important;
}

.px-7 {
  padding-left: 28px !important;
  padding-right: 28px !important;
}

.py-7 {
  padding-bottom: 28px !important;
  padding-top: 28px !important;
}

.mt-7 {
  margin-top: 28px !important;
}

.pt-7 {
  padding-top: 28px !important;
}

.mr-7 {
  margin-right: 28px !important;
}

.pr-7 {
  padding-right: 28px !important;
}

.mb-7 {
  margin-bottom: 28px !important;
}

.pb-7 {
  padding-bottom: 28px !important;
}

.ml-7 {
  margin-left: 28px !important;
}

.pl-7 {
  padding-left: 28px !important;
}

.m-8 {
  margin: 32px !important;
}

.p-8 {
  padding: 32px !important;
}

.mx-8 {
  margin-left: 32px !important;
  margin-right: 32px !important;
}

.my-8 {
  margin-bottom: 32px !important;
  margin-top: 32px !important;
}

.px-8 {
  padding-left: 32px !important;
  padding-right: 32px !important;
}

.py-8 {
  padding-bottom: 32px !important;
  padding-top: 32px !important;
}

.mt-8 {
  margin-top: 32px !important;
}

.pt-8 {
  padding-top: 32px !important;
}

.mr-8 {
  margin-right: 32px !important;
}

.pr-8 {
  padding-right: 32px !important;
}

.mb-8 {
  margin-bottom: 32px !important;
}

.pb-8 {
  padding-bottom: 32px !important;
}

.ml-8 {
  margin-left: 32px !important;
}

.pl-8 {
  padding-left: 32px !important;
}

.m-9 {
  margin: 36px !important;
}

.p-9 {
  padding: 36px !important;
}

.mx-9 {
  margin-left: 36px !important;
  margin-right: 36px !important;
}

.my-9 {
  margin-bottom: 36px !important;
  margin-top: 36px !important;
}

.px-9 {
  padding-left: 36px !important;
  padding-right: 36px !important;
}

.py-9 {
  padding-bottom: 36px !important;
  padding-top: 36px !important;
}

.mt-9 {
  margin-top: 36px !important;
}

.pt-9 {
  padding-top: 36px !important;
}

.mr-9 {
  margin-right: 36px !important;
}

.pr-9 {
  padding-right: 36px !important;
}

.mb-9 {
  margin-bottom: 36px !important;
}

.pb-9 {
  padding-bottom: 36px !important;
}

.ml-9 {
  margin-left: 36px !important;
}

.pl-9 {
  padding-left: 36px !important;
}

.m-10 {
  margin: 40px !important;
}

.p-10 {
  padding: 40px !important;
}

.mx-10 {
  margin-left: 40px !important;
  margin-right: 40px !important;
}

.my-10 {
  margin-bottom: 40px !important;
  margin-top: 40px !important;
}

.px-10 {
  padding-left: 40px !important;
  padding-right: 40px !important;
}

.py-10 {
  padding-bottom: 40px !important;
  padding-top: 40px !important;
}

.mt-10 {
  margin-top: 40px !important;
}

.pt-10 {
  padding-top: 40px !important;
}

.mr-10 {
  margin-right: 40px !important;
}

.pr-10 {
  padding-right: 40px !important;
}

.mb-10 {
  margin-bottom: 40px !important;
}

.pb-10 {
  padding-bottom: 40px !important;
}

.ml-10 {
  margin-left: 40px !important;
}

.pl-10 {
  padding-left: 40px !important;
}

.m-11 {
  margin: 44px !important;
}

.p-11 {
  padding: 44px !important;
}

.mx-11 {
  margin-left: 44px !important;
  margin-right: 44px !important;
}

.my-11 {
  margin-bottom: 44px !important;
  margin-top: 44px !important;
}

.px-11 {
  padding-left: 44px !important;
  padding-right: 44px !important;
}

.py-11 {
  padding-bottom: 44px !important;
  padding-top: 44px !important;
}

.mt-11 {
  margin-top: 44px !important;
}

.pt-11 {
  padding-top: 44px !important;
}

.mr-11 {
  margin-right: 44px !important;
}

.pr-11 {
  padding-right: 44px !important;
}

.mb-11 {
  margin-bottom: 44px !important;
}

.pb-11 {
  padding-bottom: 44px !important;
}

.ml-11 {
  margin-left: 44px !important;
}

.pl-11 {
  padding-left: 44px !important;
}

.m-12 {
  margin: 48px !important;
}

.p-12 {
  padding: 48px !important;
}

.mx-12 {
  margin-left: 48px !important;
  margin-right: 48px !important;
}

.my-12 {
  margin-bottom: 48px !important;
  margin-top: 48px !important;
}

.px-12 {
  padding-left: 48px !important;
  padding-right: 48px !important;
}

.py-12 {
  padding-bottom: 48px !important;
  padding-top: 48px !important;
}

.mt-12 {
  margin-top: 48px !important;
}

.pt-12 {
  padding-top: 48px !important;
}

.mr-12 {
  margin-right: 48px !important;
}

.pr-12 {
  padding-right: 48px !important;
}

.mb-12 {
  margin-bottom: 48px !important;
}

.pb-12 {
  padding-bottom: 48px !important;
}

.ml-12 {
  margin-left: 48px !important;
}

.pl-12 {
  padding-left: 48px !important;
}

.m-13 {
  margin: 52px !important;
}

.p-13 {
  padding: 52px !important;
}

.mx-13 {
  margin-left: 52px !important;
  margin-right: 52px !important;
}

.my-13 {
  margin-bottom: 52px !important;
  margin-top: 52px !important;
}

.px-13 {
  padding-left: 52px !important;
  padding-right: 52px !important;
}

.py-13 {
  padding-bottom: 52px !important;
  padding-top: 52px !important;
}

.mt-13 {
  margin-top: 52px !important;
}

.pt-13 {
  padding-top: 52px !important;
}

.mr-13 {
  margin-right: 52px !important;
}

.pr-13 {
  padding-right: 52px !important;
}

.mb-13 {
  margin-bottom: 52px !important;
}

.pb-13 {
  padding-bottom: 52px !important;
}

.ml-13 {
  margin-left: 52px !important;
}

.pl-13 {
  padding-left: 52px !important;
}

.m-14 {
  margin: 56px !important;
}

.p-14 {
  padding: 56px !important;
}

.mx-14 {
  margin-left: 56px !important;
  margin-right: 56px !important;
}

.my-14 {
  margin-bottom: 56px !important;
  margin-top: 56px !important;
}

.px-14 {
  padding-left: 56px !important;
  padding-right: 56px !important;
}

.py-14 {
  padding-bottom: 56px !important;
  padding-top: 56px !important;
}

.mt-14 {
  margin-top: 56px !important;
}

.pt-14 {
  padding-top: 56px !important;
}

.mr-14 {
  margin-right: 56px !important;
}

.pr-14 {
  padding-right: 56px !important;
}

.mb-14 {
  margin-bottom: 56px !important;
}

.pb-14 {
  padding-bottom: 56px !important;
}

.ml-14 {
  margin-left: 56px !important;
}

.pl-14 {
  padding-left: 56px !important;
}

.m-15 {
  margin: 60px !important;
}

.p-15 {
  padding: 60px !important;
}

.mx-15 {
  margin-left: 60px !important;
  margin-right: 60px !important;
}

.my-15 {
  margin-bottom: 60px !important;
  margin-top: 60px !important;
}

.px-15 {
  padding-left: 60px !important;
  padding-right: 60px !important;
}

.py-15 {
  padding-bottom: 60px !important;
  padding-top: 60px !important;
}

.mt-15 {
  margin-top: 60px !important;
}

.pt-15 {
  padding-top: 60px !important;
}

.mr-15 {
  margin-right: 60px !important;
}

.pr-15 {
  padding-right: 60px !important;
}

.mb-15 {
  margin-bottom: 60px !important;
}

.pb-15 {
  padding-bottom: 60px !important;
}

.ml-15 {
  margin-left: 60px !important;
}

.pl-15 {
  padding-left: 60px !important;
}

.m-16 {
  margin: 64px !important;
}

.p-16 {
  padding: 64px !important;
}

.mx-16 {
  margin-left: 64px !important;
  margin-right: 64px !important;
}

.my-16 {
  margin-bottom: 64px !important;
  margin-top: 64px !important;
}

.px-16 {
  padding-left: 64px !important;
  padding-right: 64px !important;
}

.py-16 {
  padding-bottom: 64px !important;
  padding-top: 64px !important;
}

.mt-16 {
  margin-top: 64px !important;
}

.pt-16 {
  padding-top: 64px !important;
}

.mr-16 {
  margin-right: 64px !important;
}

.pr-16 {
  padding-right: 64px !important;
}

.mb-16 {
  margin-bottom: 64px !important;
}

.pb-16 {
  padding-bottom: 64px !important;
}

.ml-16 {
  margin-left: 64px !important;
}

.pl-16 {
  padding-left: 64px !important;
}

.m-17 {
  margin: 68px !important;
}

.p-17 {
  padding: 68px !important;
}

.mx-17 {
  margin-left: 68px !important;
  margin-right: 68px !important;
}

.my-17 {
  margin-bottom: 68px !important;
  margin-top: 68px !important;
}

.px-17 {
  padding-left: 68px !important;
  padding-right: 68px !important;
}

.py-17 {
  padding-bottom: 68px !important;
  padding-top: 68px !important;
}

.mt-17 {
  margin-top: 68px !important;
}

.pt-17 {
  padding-top: 68px !important;
}

.mr-17 {
  margin-right: 68px !important;
}

.pr-17 {
  padding-right: 68px !important;
}

.mb-17 {
  margin-bottom: 68px !important;
}

.pb-17 {
  padding-bottom: 68px !important;
}

.ml-17 {
  margin-left: 68px !important;
}

.pl-17 {
  padding-left: 68px !important;
}

.m-18 {
  margin: 72px !important;
}

.p-18 {
  padding: 72px !important;
}

.mx-18 {
  margin-left: 72px !important;
  margin-right: 72px !important;
}

.my-18 {
  margin-bottom: 72px !important;
  margin-top: 72px !important;
}

.px-18 {
  padding-left: 72px !important;
  padding-right: 72px !important;
}

.py-18 {
  padding-bottom: 72px !important;
  padding-top: 72px !important;
}

.mt-18 {
  margin-top: 72px !important;
}

.pt-18 {
  padding-top: 72px !important;
}

.mr-18 {
  margin-right: 72px !important;
}

.pr-18 {
  padding-right: 72px !important;
}

.mb-18 {
  margin-bottom: 72px !important;
}

.pb-18 {
  padding-bottom: 72px !important;
}

.ml-18 {
  margin-left: 72px !important;
}

.pl-18 {
  padding-left: 72px !important;
}

.m-19 {
  margin: 76px !important;
}

.p-19 {
  padding: 76px !important;
}

.mx-19 {
  margin-left: 76px !important;
  margin-right: 76px !important;
}

.my-19 {
  margin-bottom: 76px !important;
  margin-top: 76px !important;
}

.px-19 {
  padding-left: 76px !important;
  padding-right: 76px !important;
}

.py-19 {
  padding-bottom: 76px !important;
  padding-top: 76px !important;
}

.mt-19 {
  margin-top: 76px !important;
}

.pt-19 {
  padding-top: 76px !important;
}

.mr-19 {
  margin-right: 76px !important;
}

.pr-19 {
  padding-right: 76px !important;
}

.mb-19 {
  margin-bottom: 76px !important;
}

.pb-19 {
  padding-bottom: 76px !important;
}

.ml-19 {
  margin-left: 76px !important;
}

.pl-19 {
  padding-left: 76px !important;
}

.m-20 {
  margin: 80px !important;
}

.p-20 {
  padding: 80px !important;
}

.mx-20 {
  margin-left: 80px !important;
  margin-right: 80px !important;
}

.my-20 {
  margin-bottom: 80px !important;
  margin-top: 80px !important;
}

.px-20 {
  padding-left: 80px !important;
  padding-right: 80px !important;
}

.py-20 {
  padding-bottom: 80px !important;
  padding-top: 80px !important;
}

.mt-20 {
  margin-top: 80px !important;
}

.pt-20 {
  padding-top: 80px !important;
}

.mr-20 {
  margin-right: 80px !important;
}

.pr-20 {
  padding-right: 80px !important;
}

.mb-20 {
  margin-bottom: 80px !important;
}

.pb-20 {
  padding-bottom: 80px !important;
}

.ml-20 {
  margin-left: 80px !important;
}

.pl-20 {
  padding-left: 80px !important;
}

.row {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: -12px;
}
.row .row {
  margin: 0;
}

.justify-start {
  justify-content: flex-start !important;
}

.justify-center {
  justify-content: center !important;
}

@media only screen and (max-width: 600px) {
  .justify-sm-center {
    justify-content: center !important;
  }
}
.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
}

[class*=col-] {
  box-sizing: border-box;
  padding: 12px;
}

.col-1 {
  flex: 0 0 calc(100% / 12 * 1);
  max-width: calc(100% / 12 * 1);
}

.col-2 {
  flex: 0 0 calc(100% / 12 * 2);
  max-width: calc(100% / 12 * 2);
}

.col-3 {
  flex: 0 0 calc(100% / 12 * 3);
  max-width: calc(100% / 12 * 3);
}

.col-4 {
  flex: 0 0 calc(100% / 12 * 4);
  max-width: calc(100% / 12 * 4);
}

.col-5 {
  flex: 0 0 calc(100% / 12 * 5);
  max-width: calc(100% / 12 * 5);
}

.col-6 {
  flex: 0 0 calc(100% / 12 * 6);
  max-width: calc(100% / 12 * 6);
}

.col-7 {
  flex: 0 0 calc(100% / 12 * 7);
  max-width: calc(100% / 12 * 7);
}

.col-8 {
  flex: 0 0 calc(100% / 12 * 8);
  max-width: calc(100% / 12 * 8);
}

.col-9 {
  flex: 0 0 calc(100% / 12 * 9);
  max-width: calc(100% / 12 * 9);
}

.col-10 {
  flex: 0 0 calc(100% / 12 * 10);
  max-width: calc(100% / 12 * 10);
}

.col-11 {
  flex: 0 0 calc(100% / 12 * 11);
  max-width: calc(100% / 12 * 11);
}

.col-12 {
  flex: 0 0 calc(100% / 12 * 12);
  max-width: calc(100% / 12 * 12);
}

@media only screen and (max-width: 600px) {
  .col-xs-1 {
    flex: 0 0 calc(100% / 12 * 1);
    max-width: calc(100% / 12 * 1);
  }
  .col-xs-2 {
    flex: 0 0 calc(100% / 12 * 2);
    max-width: calc(100% / 12 * 2);
  }
  .col-xs-3 {
    flex: 0 0 calc(100% / 12 * 3);
    max-width: calc(100% / 12 * 3);
  }
  .col-xs-4 {
    flex: 0 0 calc(100% / 12 * 4);
    max-width: calc(100% / 12 * 4);
  }
  .col-xs-5 {
    flex: 0 0 calc(100% / 12 * 5);
    max-width: calc(100% / 12 * 5);
  }
  .col-xs-6 {
    flex: 0 0 calc(100% / 12 * 6);
    max-width: calc(100% / 12 * 6);
  }
  .col-xs-7 {
    flex: 0 0 calc(100% / 12 * 7);
    max-width: calc(100% / 12 * 7);
  }
  .col-xs-8 {
    flex: 0 0 calc(100% / 12 * 8);
    max-width: calc(100% / 12 * 8);
  }
  .col-xs-9 {
    flex: 0 0 calc(100% / 12 * 9);
    max-width: calc(100% / 12 * 9);
  }
  .col-xs-10 {
    flex: 0 0 calc(100% / 12 * 10);
    max-width: calc(100% / 12 * 10);
  }
  .col-xs-11 {
    flex: 0 0 calc(100% / 12 * 11);
    max-width: calc(100% / 12 * 11);
  }
  .col-xs-12 {
    flex: 0 0 calc(100% / 12 * 12);
    max-width: calc(100% / 12 * 12);
  }
}
@media only screen and (min-width: 600px) {
  .col-sm-1 {
    flex: 0 0 calc(100% / 12 * 1);
    max-width: calc(100% / 12 * 1);
  }
  .col-sm-2 {
    flex: 0 0 calc(100% / 12 * 2);
    max-width: calc(100% / 12 * 2);
  }
  .col-sm-3 {
    flex: 0 0 calc(100% / 12 * 3);
    max-width: calc(100% / 12 * 3);
  }
  .col-sm-4 {
    flex: 0 0 calc(100% / 12 * 4);
    max-width: calc(100% / 12 * 4);
  }
  .col-sm-5 {
    flex: 0 0 calc(100% / 12 * 5);
    max-width: calc(100% / 12 * 5);
  }
  .col-sm-6 {
    flex: 0 0 calc(100% / 12 * 6);
    max-width: calc(100% / 12 * 6);
  }
  .col-sm-7 {
    flex: 0 0 calc(100% / 12 * 7);
    max-width: calc(100% / 12 * 7);
  }
  .col-sm-8 {
    flex: 0 0 calc(100% / 12 * 8);
    max-width: calc(100% / 12 * 8);
  }
  .col-sm-9 {
    flex: 0 0 calc(100% / 12 * 9);
    max-width: calc(100% / 12 * 9);
  }
  .col-sm-10 {
    flex: 0 0 calc(100% / 12 * 10);
    max-width: calc(100% / 12 * 10);
  }
  .col-sm-11 {
    flex: 0 0 calc(100% / 12 * 11);
    max-width: calc(100% / 12 * 11);
  }
  .col-sm-12 {
    flex: 0 0 calc(100% / 12 * 12);
    max-width: calc(100% / 12 * 12);
  }
}
@media only screen and (min-width: 900px) {
  .col-md-1 {
    flex: 0 0 calc(100% / 12 * 1);
    max-width: calc(100% / 12 * 1);
  }
  .col-md-2 {
    flex: 0 0 calc(100% / 12 * 2);
    max-width: calc(100% / 12 * 2);
  }
  .col-md-3 {
    flex: 0 0 calc(100% / 12 * 3);
    max-width: calc(100% / 12 * 3);
  }
  .col-md-4 {
    flex: 0 0 calc(100% / 12 * 4);
    max-width: calc(100% / 12 * 4);
  }
  .col-md-5 {
    flex: 0 0 calc(100% / 12 * 5);
    max-width: calc(100% / 12 * 5);
  }
  .col-md-6 {
    flex: 0 0 calc(100% / 12 * 6);
    max-width: calc(100% / 12 * 6);
  }
  .col-md-7 {
    flex: 0 0 calc(100% / 12 * 7);
    max-width: calc(100% / 12 * 7);
  }
  .col-md-8 {
    flex: 0 0 calc(100% / 12 * 8);
    max-width: calc(100% / 12 * 8);
  }
  .col-md-9 {
    flex: 0 0 calc(100% / 12 * 9);
    max-width: calc(100% / 12 * 9);
  }
  .col-md-10 {
    flex: 0 0 calc(100% / 12 * 10);
    max-width: calc(100% / 12 * 10);
  }
  .col-md-11 {
    flex: 0 0 calc(100% / 12 * 11);
    max-width: calc(100% / 12 * 11);
  }
  .col-md-12 {
    flex: 0 0 calc(100% / 12 * 12);
    max-width: calc(100% / 12 * 12);
  }
}
.mt-0 {
  margin-top: 0px !important;
}

.mt-n0 {
  margin-top: -0px !important;
}

.pt-0 {
  padding-top: 0px !important;
}

.mb-0 {
  margin-bottom: 0px !important;
}

.mb-n0 {
  margin-bottom: -0px !important;
}

.pb-0 {
  padding-bottom: 0px !important;
}

.ml-0 {
  margin-left: 0px !important;
}

.ml-n0 {
  margin-left: -0px !important;
}

.pl-0 {
  padding-left: 0px !important;
}

.mr-0 {
  margin-right: 0px !important;
}

.mr-n0 {
  margin-right: -0px !important;
}

.pr-0 {
  padding-right: 0px !important;
}

.ma-0 {
  margin: 0px !important;
}

.ma-n0 {
  margin: -0px !important;
}

.mx-0 {
  margin-left: 0px !important;
  margin-right: 0px !important;
}

.my-0 {
  margin-bottom: 0px !important;
  margin-top: 0px !important;
}

.mx-n0 {
  margin-left: -0px !important;
  margin-right: -0px !important;
}

.my-n0 {
  margin-bottom: -0px !important;
  margin-top: -0px !important;
}

.pa-0 {
  padding: 0px !important;
}

.px-0 {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.py-0 {
  padding-bottom: 0px !important;
  padding-top: 0px !important;
}

.mt-1 {
  margin-top: 4px !important;
}

.mt-n1 {
  margin-top: -4px !important;
}

.pt-1 {
  padding-top: 4px !important;
}

.mb-1 {
  margin-bottom: 4px !important;
}

.mb-n1 {
  margin-bottom: -4px !important;
}

.pb-1 {
  padding-bottom: 4px !important;
}

.ml-1 {
  margin-left: 4px !important;
}

.ml-n1 {
  margin-left: -4px !important;
}

.pl-1 {
  padding-left: 4px !important;
}

.mr-1 {
  margin-right: 4px !important;
}

.mr-n1 {
  margin-right: -4px !important;
}

.pr-1 {
  padding-right: 4px !important;
}

.ma-1 {
  margin: 4px !important;
}

.ma-n1 {
  margin: -4px !important;
}

.mx-1 {
  margin-left: 4px !important;
  margin-right: 4px !important;
}

.my-1 {
  margin-bottom: 4px !important;
  margin-top: 4px !important;
}

.mx-n1 {
  margin-left: -4px !important;
  margin-right: -4px !important;
}

.my-n1 {
  margin-bottom: -4px !important;
  margin-top: -4px !important;
}

.pa-1 {
  padding: 4px !important;
}

.px-1 {
  padding-left: 4px !important;
  padding-right: 4px !important;
}

.py-1 {
  padding-bottom: 4px !important;
  padding-top: 4px !important;
}

.mt-2 {
  margin-top: 8px !important;
}

.mt-n2 {
  margin-top: -8px !important;
}

.pt-2 {
  padding-top: 8px !important;
}

.mb-2 {
  margin-bottom: 8px !important;
}

.mb-n2 {
  margin-bottom: -8px !important;
}

.pb-2 {
  padding-bottom: 8px !important;
}

.ml-2 {
  margin-left: 8px !important;
}

.ml-n2 {
  margin-left: -8px !important;
}

.pl-2 {
  padding-left: 8px !important;
}

.mr-2 {
  margin-right: 8px !important;
}

.mr-n2 {
  margin-right: -8px !important;
}

.pr-2 {
  padding-right: 8px !important;
}

.ma-2 {
  margin: 8px !important;
}

.ma-n2 {
  margin: -8px !important;
}

.mx-2 {
  margin-left: 8px !important;
  margin-right: 8px !important;
}

.my-2 {
  margin-bottom: 8px !important;
  margin-top: 8px !important;
}

.mx-n2 {
  margin-left: -8px !important;
  margin-right: -8px !important;
}

.my-n2 {
  margin-bottom: -8px !important;
  margin-top: -8px !important;
}

.pa-2 {
  padding: 8px !important;
}

.px-2 {
  padding-left: 8px !important;
  padding-right: 8px !important;
}

.py-2 {
  padding-bottom: 8px !important;
  padding-top: 8px !important;
}

.mt-3 {
  margin-top: 12px !important;
}

.mt-n3 {
  margin-top: -12px !important;
}

.pt-3 {
  padding-top: 12px !important;
}

.mb-3 {
  margin-bottom: 12px !important;
}

.mb-n3 {
  margin-bottom: -12px !important;
}

.pb-3 {
  padding-bottom: 12px !important;
}

.ml-3 {
  margin-left: 12px !important;
}

.ml-n3 {
  margin-left: -12px !important;
}

.pl-3 {
  padding-left: 12px !important;
}

.mr-3 {
  margin-right: 12px !important;
}

.mr-n3 {
  margin-right: -12px !important;
}

.pr-3 {
  padding-right: 12px !important;
}

.ma-3 {
  margin: 12px !important;
}

.ma-n3 {
  margin: -12px !important;
}

.mx-3 {
  margin-left: 12px !important;
  margin-right: 12px !important;
}

.my-3 {
  margin-bottom: 12px !important;
  margin-top: 12px !important;
}

.mx-n3 {
  margin-left: -12px !important;
  margin-right: -12px !important;
}

.my-n3 {
  margin-bottom: -12px !important;
  margin-top: -12px !important;
}

.pa-3 {
  padding: 12px !important;
}

.px-3 {
  padding-left: 12px !important;
  padding-right: 12px !important;
}

.py-3 {
  padding-bottom: 12px !important;
  padding-top: 12px !important;
}

.mt-4 {
  margin-top: 16px !important;
}

.mt-n4 {
  margin-top: -16px !important;
}

.pt-4 {
  padding-top: 16px !important;
}

.mb-4 {
  margin-bottom: 16px !important;
}

.mb-n4 {
  margin-bottom: -16px !important;
}

.pb-4 {
  padding-bottom: 16px !important;
}

.ml-4 {
  margin-left: 16px !important;
}

.ml-n4 {
  margin-left: -16px !important;
}

.pl-4 {
  padding-left: 16px !important;
}

.mr-4 {
  margin-right: 16px !important;
}

.mr-n4 {
  margin-right: -16px !important;
}

.pr-4 {
  padding-right: 16px !important;
}

.ma-4 {
  margin: 16px !important;
}

.ma-n4 {
  margin: -16px !important;
}

.mx-4 {
  margin-left: 16px !important;
  margin-right: 16px !important;
}

.my-4 {
  margin-bottom: 16px !important;
  margin-top: 16px !important;
}

.mx-n4 {
  margin-left: -16px !important;
  margin-right: -16px !important;
}

.my-n4 {
  margin-bottom: -16px !important;
  margin-top: -16px !important;
}

.pa-4 {
  padding: 16px !important;
}

.px-4 {
  padding-left: 16px !important;
  padding-right: 16px !important;
}

.py-4 {
  padding-bottom: 16px !important;
  padding-top: 16px !important;
}

.mt-5 {
  margin-top: 20px !important;
}

.mt-n5 {
  margin-top: -20px !important;
}

.pt-5 {
  padding-top: 20px !important;
}

.mb-5 {
  margin-bottom: 20px !important;
}

.mb-n5 {
  margin-bottom: -20px !important;
}

.pb-5 {
  padding-bottom: 20px !important;
}

.ml-5 {
  margin-left: 20px !important;
}

.ml-n5 {
  margin-left: -20px !important;
}

.pl-5 {
  padding-left: 20px !important;
}

.mr-5 {
  margin-right: 20px !important;
}

.mr-n5 {
  margin-right: -20px !important;
}

.pr-5 {
  padding-right: 20px !important;
}

.ma-5 {
  margin: 20px !important;
}

.ma-n5 {
  margin: -20px !important;
}

.mx-5 {
  margin-left: 20px !important;
  margin-right: 20px !important;
}

.my-5 {
  margin-bottom: 20px !important;
  margin-top: 20px !important;
}

.mx-n5 {
  margin-left: -20px !important;
  margin-right: -20px !important;
}

.my-n5 {
  margin-bottom: -20px !important;
  margin-top: -20px !important;
}

.pa-5 {
  padding: 20px !important;
}

.px-5 {
  padding-left: 20px !important;
  padding-right: 20px !important;
}

.py-5 {
  padding-bottom: 20px !important;
  padding-top: 20px !important;
}

.mt-6 {
  margin-top: 24px !important;
}

.mt-n6 {
  margin-top: -24px !important;
}

.pt-6 {
  padding-top: 24px !important;
}

.mb-6 {
  margin-bottom: 24px !important;
}

.mb-n6 {
  margin-bottom: -24px !important;
}

.pb-6 {
  padding-bottom: 24px !important;
}

.ml-6 {
  margin-left: 24px !important;
}

.ml-n6 {
  margin-left: -24px !important;
}

.pl-6 {
  padding-left: 24px !important;
}

.mr-6 {
  margin-right: 24px !important;
}

.mr-n6 {
  margin-right: -24px !important;
}

.pr-6 {
  padding-right: 24px !important;
}

.ma-6 {
  margin: 24px !important;
}

.ma-n6 {
  margin: -24px !important;
}

.mx-6 {
  margin-left: 24px !important;
  margin-right: 24px !important;
}

.my-6 {
  margin-bottom: 24px !important;
  margin-top: 24px !important;
}

.mx-n6 {
  margin-left: -24px !important;
  margin-right: -24px !important;
}

.my-n6 {
  margin-bottom: -24px !important;
  margin-top: -24px !important;
}

.pa-6 {
  padding: 24px !important;
}

.px-6 {
  padding-left: 24px !important;
  padding-right: 24px !important;
}

.py-6 {
  padding-bottom: 24px !important;
  padding-top: 24px !important;
}

.mt-7 {
  margin-top: 28px !important;
}

.mt-n7 {
  margin-top: -28px !important;
}

.pt-7 {
  padding-top: 28px !important;
}

.mb-7 {
  margin-bottom: 28px !important;
}

.mb-n7 {
  margin-bottom: -28px !important;
}

.pb-7 {
  padding-bottom: 28px !important;
}

.ml-7 {
  margin-left: 28px !important;
}

.ml-n7 {
  margin-left: -28px !important;
}

.pl-7 {
  padding-left: 28px !important;
}

.mr-7 {
  margin-right: 28px !important;
}

.mr-n7 {
  margin-right: -28px !important;
}

.pr-7 {
  padding-right: 28px !important;
}

.ma-7 {
  margin: 28px !important;
}

.ma-n7 {
  margin: -28px !important;
}

.mx-7 {
  margin-left: 28px !important;
  margin-right: 28px !important;
}

.my-7 {
  margin-bottom: 28px !important;
  margin-top: 28px !important;
}

.mx-n7 {
  margin-left: -28px !important;
  margin-right: -28px !important;
}

.my-n7 {
  margin-bottom: -28px !important;
  margin-top: -28px !important;
}

.pa-7 {
  padding: 28px !important;
}

.px-7 {
  padding-left: 28px !important;
  padding-right: 28px !important;
}

.py-7 {
  padding-bottom: 28px !important;
  padding-top: 28px !important;
}

.mt-8 {
  margin-top: 32px !important;
}

.mt-n8 {
  margin-top: -32px !important;
}

.pt-8 {
  padding-top: 32px !important;
}

.mb-8 {
  margin-bottom: 32px !important;
}

.mb-n8 {
  margin-bottom: -32px !important;
}

.pb-8 {
  padding-bottom: 32px !important;
}

.ml-8 {
  margin-left: 32px !important;
}

.ml-n8 {
  margin-left: -32px !important;
}

.pl-8 {
  padding-left: 32px !important;
}

.mr-8 {
  margin-right: 32px !important;
}

.mr-n8 {
  margin-right: -32px !important;
}

.pr-8 {
  padding-right: 32px !important;
}

.ma-8 {
  margin: 32px !important;
}

.ma-n8 {
  margin: -32px !important;
}

.mx-8 {
  margin-left: 32px !important;
  margin-right: 32px !important;
}

.my-8 {
  margin-bottom: 32px !important;
  margin-top: 32px !important;
}

.mx-n8 {
  margin-left: -32px !important;
  margin-right: -32px !important;
}

.my-n8 {
  margin-bottom: -32px !important;
  margin-top: -32px !important;
}

.pa-8 {
  padding: 32px !important;
}

.px-8 {
  padding-left: 32px !important;
  padding-right: 32px !important;
}

.py-8 {
  padding-bottom: 32px !important;
  padding-top: 32px !important;
}

.mt-9 {
  margin-top: 36px !important;
}

.mt-n9 {
  margin-top: -36px !important;
}

.pt-9 {
  padding-top: 36px !important;
}

.mb-9 {
  margin-bottom: 36px !important;
}

.mb-n9 {
  margin-bottom: -36px !important;
}

.pb-9 {
  padding-bottom: 36px !important;
}

.ml-9 {
  margin-left: 36px !important;
}

.ml-n9 {
  margin-left: -36px !important;
}

.pl-9 {
  padding-left: 36px !important;
}

.mr-9 {
  margin-right: 36px !important;
}

.mr-n9 {
  margin-right: -36px !important;
}

.pr-9 {
  padding-right: 36px !important;
}

.ma-9 {
  margin: 36px !important;
}

.ma-n9 {
  margin: -36px !important;
}

.mx-9 {
  margin-left: 36px !important;
  margin-right: 36px !important;
}

.my-9 {
  margin-bottom: 36px !important;
  margin-top: 36px !important;
}

.mx-n9 {
  margin-left: -36px !important;
  margin-right: -36px !important;
}

.my-n9 {
  margin-bottom: -36px !important;
  margin-top: -36px !important;
}

.pa-9 {
  padding: 36px !important;
}

.px-9 {
  padding-left: 36px !important;
  padding-right: 36px !important;
}

.py-9 {
  padding-bottom: 36px !important;
  padding-top: 36px !important;
}

.mt-10 {
  margin-top: 40px !important;
}

.mt-n10 {
  margin-top: -40px !important;
}

.pt-10 {
  padding-top: 40px !important;
}

.mb-10 {
  margin-bottom: 40px !important;
}

.mb-n10 {
  margin-bottom: -40px !important;
}

.pb-10 {
  padding-bottom: 40px !important;
}

.ml-10 {
  margin-left: 40px !important;
}

.ml-n10 {
  margin-left: -40px !important;
}

.pl-10 {
  padding-left: 40px !important;
}

.mr-10 {
  margin-right: 40px !important;
}

.mr-n10 {
  margin-right: -40px !important;
}

.pr-10 {
  padding-right: 40px !important;
}

.ma-10 {
  margin: 40px !important;
}

.ma-n10 {
  margin: -40px !important;
}

.mx-10 {
  margin-left: 40px !important;
  margin-right: 40px !important;
}

.my-10 {
  margin-bottom: 40px !important;
  margin-top: 40px !important;
}

.mx-n10 {
  margin-left: -40px !important;
  margin-right: -40px !important;
}

.my-n10 {
  margin-bottom: -40px !important;
  margin-top: -40px !important;
}

.pa-10 {
  padding: 40px !important;
}

.px-10 {
  padding-left: 40px !important;
  padding-right: 40px !important;
}

.py-10 {
  padding-bottom: 40px !important;
  padding-top: 40px !important;
}

/* CSS Document */
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
font,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend {
  background: none repeat scroll 0 0 transparent;
  border: 0 none;
  font-size: 100%;
  margin: 0;
  outline: 0 none;
  padding: 0;
  vertical-align: baseline;
  -webkit-text-size-adjust: none;
}

input,
textarea,
button {
  appearance: none;
}

input:focus,
textarea:focus {
  appearance: none;
  outline: none;
}

ol,
ul {
  list-style: none outside none;
}

blockquote,
q {
  quotes: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
  border-color: rgba(0, 0, 0, 0.8);
}

/*, table, caption, tbody, tfoot, thead, tr, th, td
*/
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

* {
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
}
*:before, *:after {
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
}

body {
  font-family: "Noto Sans", sans-serif;
  background-color: #F5F5F0;
  color: #181818;
  font-size: 16px;
  line-height: 1.4;
  margin: 0px;
  padding: 0px;
  position: relative;
  height: 100%;
}

/* 960 Grid */
.container {
  box-sizing: border-box;
  max-width: 1120px;
  margin: 0 auto;
  padding: 0;
  width: 100%;
}

.grid-wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.grid_a {
  width: 100%;
  padding-bottom: 10px;
}
@media only screen and (min-width: 600px) {
  .grid_a {
    flex: 1 0 33.3333333333%;
    padding: 0;
    max-width: 33.3333333333%;
  }
}

.grid_b {
  width: 100%;
  padding-bottom: 10px;
}
@media only screen and (min-width: 600px) {
  .grid_b {
    flex: 1 0 66.6666666667%;
    padding: 0;
    max-width: 66.6666666667%;
  }
}

.grid_d {
  width: 100%;
  padding-bottom: 10px;
}
@media only screen and (min-width: 960px) {
  .grid_d {
    max-width: 460px;
    padding: 0;
  }
}

.grid_c {
  width: 100%;
  max-width: 940px;
}

/* set up */
.site-wrapper {
  max-width: 100vw;
}

.responsive-image {
  max-width: 100%;
  height: auto;
  width: 100%;
}

#header {
  position: fixed;
  position: relative;
  top: 0px;
  left: 0px;
  width: 100%;
  z-index: 1000;
  min-height: 162px;
}

.hdrTopper {
  height: 120px;
  background-color: rgb(255, 255, 255);
  display: block;
}
.hdrTopper h1 {
  color: #0063D3;
  font: 700 32px/29px "Christmas Century", "Times New Roman", serif;
  padding-top: 30px;
}

#header .logoWrapper {
  float: left;
  display: block;
  width: 720px;
}
#header .hdrComms {
  float: right;
  width: 180px;
  display: block;
  padding-top: 5px;
  color: #0063D3;
  text-align: right;
}
#header .hdrComms .telComms {
  font: 700 20px/32px "Noto Sans", sans-serif;
  padding: 5px 0;
}
#header .hdrComms .telComms span {
  float: right;
  display: block;
  height: 32px;
}
#header .hdrComms .telComms span.tele, #header .hdrComms .telComms span.fax {
  width: 32px;
  margin-right: 10px;
}
#header .hdrComms .telComms span.tele {
  background: url("./assets/images/telIconBlu.png") no-repeat;
}
#header .hdrComms .telComms span.fax {
  background: url("./assets/images/faxIconBlu.png") no-repeat;
}
#header .navHolder {
  position: relative;
  height: 42px;
  width: 100%;
  transition: box-shadow 0.2s ease, background 0.2s ease;
  background-color: #0063D3;
  z-index: 999;
  color: rgb(255, 255, 255);
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.5);
}
#header .navHolder.floatAbove {
  position: fixed;
  top: 0px;
  left: 0px;
}

body {
  background-image: url("/images/bg002.jpg");
  background-repeat: no-repeat;
  background-position: top center;
  background-attachment: fixed;
  background-size: cover;
}

main {
  background-color: rgba(245, 245, 240, 0.75);
}
main .container {
  background-color: rgba(245, 245, 240, 0.75);
  box-sizing: border-box;
  min-height: 440px;
  padding: 0 12px;
}

#googlemap {
  width: 620px;
  height: 400px;
}

/* Steel Tables */
#tblHolder {
  position: relative;
  margin: 20px 0 50px;
  margin: 20px 0 0;
}
#tblHolder .grid_c {
  display: none;
}

.halfCell {
  text-align: center;
}

.mitreGuide {
  position: relative;
}
@media only screen and (max-width: 599px) {
  .mitreGuide {
    align-items: center;
    display: flex;
    flex-direction: column;
  }
}
.mitreGuide .mitreInfo {
  width: 300px;
  height: 26px;
}
@media only screen and (max-width: 599px) {
  .mitreGuide .mitreInfo {
    position: relative;
  }
}
.mitreGuide .mitreInfo i {
  position: absolute;
  left: 0;
  top: 26px;
  font: 700 20px/20px "Noto Sans", sans-serif;
}
.mitreGuide .mitreInfo p {
  font: 500 12px/20px "Noto Sans", sans-serif;
  text-align: center;
}
.mitreGuide .mitreInfo p span {
  font: 500 9px/10px "Noto Sans", sans-serif;
  text-align: center;
  display: block;
  margin-top: -4px;
}
.mitreGuide .holder {
  margin: 0 0 0 15px;
  width: 285px;
  height: 64px;
}
@media only screen and (max-width: 599px) {
  .mitreGuide .holder {
    margin: 0;
  }
}
.mitreGuide .holder span {
  background: url("/images/mitre-guide.png") no-repeat;
  width: 285px;
  height: 64px;
  display: block;
}
.mitreGuide .holder span.a1 {
  background-position: 0 0;
}
.mitreGuide .holder span.a2 {
  background-position: 0 -66px;
}
.mitreGuide .holder span.a3 {
  background-position: 0 -132px;
}
.mitreGuide .holder span.a4 {
  background-position: 0 -198px;
}
.mitreGuide .holder span.a5 {
  background-position: 0 -264px;
}
.mitreGuide .holder span.a6 {
  background-position: 0 -330px;
}
.mitreGuide .holder span.a7 {
  background-position: 0 -396px;
}
.mitreGuide .holder span.a8 {
  background-position: 0 -462px;
}
.mitreGuide .holder span.a9 {
  background-position: 0 -528px;
}
.mitreGuide .holder span.a10 {
  background-position: 0 -594px;
}
.mitreGuide .holder span.a11 {
  background-position: 0 -660px;
}
.mitreGuide .holder span.a12 {
  background-position: 0 -726px;
}
.mitreGuide .holder span.a13 {
  background-position: 0 -792px;
}
.mitreGuide .holder span.a14 {
  background-position: 0 -858px;
}
.mitreGuide .holder span.a15 {
  background-position: 0 -924px;
}
.mitreGuide .holder span.a16 {
  background-position: 0 -990px;
}
.mitreGuide .holder span.a17 {
  background-position: 0 -1056px;
}
.mitreGuide .holder span.a18 {
  background-position: 0 -1122px;
}
.mitreGuide .holder span.a19 {
  background-position: 0 -1188px;
}
.mitreGuide .holder span.a20 {
  background-position: 0 -1254px;
}
.mitreGuide .holder span.a21 {
  background-position: 0 -1320px;
}
.mitreGuide .holder span.a22 {
  background-position: 0 -1386px;
}
.mitreGuide .holder span.a23 {
  background-position: 0 -1452px;
}
.mitreGuide .holder span.a24 {
  background-position: 0 -1518px;
}
.mitreGuide .holder span.a25 {
  background-position: 0 -1584px;
}
.mitreGuide .holder span.a26 {
  background-position: 0 -1650px;
}
.mitreGuide .holder span.a27 {
  background-position: 0 -1716px;
}
.mitreGuide .holder span.a28 {
  background-position: 0 -1782px;
}
.mitreGuide .holder span.a29 {
  background-position: 0 -1848px;
}
.mitreGuide .holder span.a30 {
  background-position: 0 -1914px;
}
.mitreGuide .holder span.a31 {
  background-position: 0 -1980px;
}
.mitreGuide .holder span.a32 {
  background-position: 0 -2046px;
}
.mitreGuide .holder span.a33 {
  background-position: 0 -2112px;
}
.mitreGuide .holder span.a34 {
  background-position: 0 -2178px;
}
.mitreGuide .holder span.a35 {
  background-position: 0 -2244px;
}
.mitreGuide .holder span.a36 {
  background-position: 0 -2310px;
}
.mitreGuide .holder span.a37 {
  background-position: 0 -2376px;
}
.mitreGuide .holder span.a38 {
  background-position: 0 -2442px;
}
.mitreGuide .holder span.a39 {
  background-position: 0 -2508px;
}
.mitreGuide .holder span.a40 {
  background-position: 0 -2574px;
}
.mitreGuide .holder span.a41 {
  background-position: 0 -2640px;
}
.mitreGuide .holder span.a42 {
  background-position: 0 -2706px;
}
.mitreGuide .holder span.a43 {
  background-position: 0 -2772px;
}
.mitreGuide .holder span.a44 {
  background-position: 0 -2838px;
}

.baseSpace {
  height: 30px;
  display: block;
}

.steelbookDownload {
  align-items: center;
  display: flex;
  font-size: 22px;
  margin-bottom: 20px;
}
.steelbookDownload i {
  float: left;
  display: block;
  width: 46px;
  height: 46px;
  margin-right: 10px;
  background: url("/images/pdfIcon.png") no-repeat;
}

.legalInfoBase {
  font-weight: 400;
}

img.about {
  display: inline-block;
  margin-bottom: 16px;
}
@media only screen and (max-width: 599px) {
  img.about {
    max-width: 100%;
    width: 100%;
  }
}
@media only screen and (min-width: 600px) {
  img.about.left {
    float: left;
    margin-right: 16px;
  }
  img.about.right {
    float: right;
    margin-left: 16px;
  }
}

ol.numbered-list {
  counter-reset: list;
}
ol.numbered-list > li {
  list-style: none;
  padding-bottom: 8px;
}
ol.numbered-list > li:before {
  content: "(" counter(list) ") ";
  counter-increment: list;
}

ul.techInfo {
  padding: 5px 0 5px 20px;
}

.slider {
  margin: 0 auto;
  padding: 0;
  width: 700px;
  /* Update to your slider width */
  height: 467px;
  /* Update to your slider height */
  position: relative;
  overflow: hidden;
  border: 4px solid rgba(0, 0, 0, 0.5);
  border-radius: 4px;
}

#slides {
  display: none;
}
#slides .slidesjs-navigation {
  margin-top: 5px;
}

a.slidesjs-next, a.slidesjs-previous, a.slidesjs-play, a.slidesjs-stop {
  background-image: url("/images/btns-next-prev.png");
  background-repeat: no-repeat;
  display: block;
  width: 12px;
  height: 18px;
  overflow: hidden;
  text-indent: -9999px;
  float: left;
  margin-right: 5px;
}
a.slidesjs-next {
  margin-right: 10px;
  background-position: -12px 0;
}
a:hover.slidesjs-next {
  background-position: -12px -18px;
}
a.slidesjs-previous {
  background-position: 0 0;
}
a:hover.slidesjs-previous {
  background-position: 0 -18px;
}
a.slidesjs-play {
  width: 15px;
  background-position: -25px 0;
}
a:hover.slidesjs-play {
  background-position: -25px -18px;
}
a.slidesjs-stop {
  width: 18px;
  background-position: -41px 0;
}
a:hover.slidesjs-stop {
  background-position: -41px -18px;
}

.slidesjs-pagination {
  margin: 7px 0 0;
  float: right;
  list-style: none;
}
.slidesjs-pagination li {
  float: left;
  margin: 0 1px;
}
.slidesjs-pagination li a {
  display: block;
  width: 13px;
  height: 0;
  padding-top: 13px;
  background-image: url("/images/pagination.png");
  background-position: 0 0;
  float: left;
  overflow: hidden;
}
.slidesjs-pagination li a.active {
  background-position: 0 -13px;
}
.slidesjs-pagination li a:hover {
  background-position: 0 -26px;
}
.slidesjs-pagination li a:hover.active {
  background-position: 0 -13px;
}

#slides {
  display: none;
}
#slides a:link, #slides a:visited {
  color: #333;
}
#slides a:hover, #slides a:active {
  color: #9e2020;
}

h2.contactsTitle {
  text-align: center;
  font: 700 34px/40px "Noto Sans", sans-serif;
}

.slideContainer {
  margin: 0 auto;
}

.contact-item {
  width: 100%;
}
.contact-item span.photo {
  width: 100%;
  display: block;
  overflow: hidden;
  position: relative;
  border-radius: 4px;
}
.contact-item span.photo img {
  width: 100%;
  height: auto;
}
.contact-item b.name {
  width: 100%;
  display: block;
  text-align: center;
  font: 700 20px/24px "Noto Sans", sans-serif;
}
.contact-item a.eLink {
  transition: all 250ms cubic-bezier(0.4, 0, 0.2, 1) 0s;
  display: block;
  text-align: center;
  margin: 5px 0px 10px;
  font-size: 16px;
  padding: 8px;
  background: #0063D3;
  border-radius: 4px;
  color: #fff;
}
.contact-item a.eLink:hover {
  background: rgb(0, 60, 128);
  text-decoration: none;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.5);
  cursor: pointer;
}

ul.contactsList {
  display: inline-flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
  position: relative;
}
ul.contactsList li {
  margin: 0px 10px 10px;
  width: 215px;
}
ul.contactsList li span.photo {
  width: 100%;
  display: block;
  overflow: hidden;
  position: relative;
  border-radius: 4px;
}
ul.contactsList li span.photo img {
  width: 100%;
  height: auto;
}
ul.contactsList li b.name {
  width: 100%;
  display: block;
  text-align: center;
  font: 700 20px/24px "Noto Sans", sans-serif;
}
ul.contactsList li a.eLink {
  display: block;
  text-align: center;
  padding: 4px;
  margin: 5px 0px 10px;
  font: 700 20px/24px "Noto Sans", sans-serif;
  background: #0063D3;
  border-radius: 4px;
  color: #fff;
}
ul.contactsList li a.eLink:hover {
  background: #003C80;
  text-decoration: none;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.5);
  cursor: pointer;
}

.gmap_canvas {
  position: relative;
  height: 0;
  padding-bottom: 48.3870967742%;
  width: 100%;
  overflow: hidden;
  background: none !important;
}
.gmap_canvas iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.btn {
  transition: all 250ms cubic-bezier(0.4, 0, 0.2, 1) 0s;
  appearance: none;
  background-color: rgba(0, 0, 0, 0);
  border: 0 none;
  cursor: pointer;
  display: inline-flex;
  padding: 0;
  margin: 0;
}

.btn--icon {
  border-radius: 16px;
  height: 32px;
  width: 32px;
}

/* */
.btn-close-menu {
  position: absolute;
  right: 5px;
  top: 5px;
}
.btn-close-menu::before, .btn-close-menu::after {
  background-color: #333;
  content: " ";
  height: 22px;
  left: 15px;
  position: absolute;
  top: 5px;
  width: 2px;
}
.btn-close-menu::before {
  transform: rotate(45deg);
}
.btn-close-menu::after {
  transform: rotate(-45deg);
}
.btn-close-menu:focus, .btn-close-menu:hover {
  background-color: rgba(0, 0, 0, 0.1);
}

.main-footer {
  color: #1b1b1b;
  padding: 0;
  width: 100%;
  z-index: 2;
  transition: all 0.2s ease;
  background-color: rgba(208, 208, 208, 0.9);
}
.main-footer > .container {
  background-color: rgb(208, 208, 208);
}
.main-footer .footer-address {
  font-size: 20px;
}

.sub-footer {
  align-items: center;
  background-color: #1b1b1b;
  color: rgba(208, 208, 208, 0.9);
  display: flex;
  height: 30px;
  justify-content: flex-end;
}
.sub-footer .copyright {
  text-align: right;
}

.header {
  transition: box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0s;
  box-shadow: 0 0 0 rgba(0, 0, 0, 0);
  position: relative;
  top: 0px;
  left: 0px;
  width: 100%;
  z-index: 2;
}
.header.is-floating {
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.5);
}
.header .header-container {
  display: flex;
  justify-content: space-between;
  max-width: 1120px;
  margin: 0 auto;
  padding: 10px 0;
  width: 100%;
}
.header .container {
  max-width: 1120px;
  width: 100%;
}

.header-topper {
  background-color: rgb(255, 255, 255);
  display: block;
  padding: 5px;
}
@media only screen and (min-width: 960px) {
  .header-topper {
    padding: 10px 0 20px;
  }
}
.header-topper .container {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
@media only screen and (min-width: 960px) {
  .header-topper .container {
    flex-direction: row;
    justify-content: space-between;
  }
}
.header-topper h1 {
  color: #0063D3;
  font-family: "Christmas Century", "Times New Roman", serif;
  font-size: 22px;
  font-weight: 700;
  line-height: 1;
}
@media only screen and (min-width: 600px) {
  .header-topper h1 {
    font-size: 32px;
  }
}
.header-topper h3 {
  color: #0063D3;
  font-family: "Noto Sans", sans-serif;
  font-size: 16px;
  font-weight: 700;
  line-height: 1;
}
@media only screen and (min-width: 600px) {
  .header-topper h3 {
    font-size: 22px;
  }
}
.header-topper h3 i {
  font-style: normal;
}

.header-comms {
  display: flex;
  flex-direction: row;
  padding-top: 5px;
  color: #0063D3;
  text-align: right;
  width: 100%;
}
@media only screen and (min-width: 960px) {
  .header-comms {
    flex-direction: column;
    width: 180px;
  }
}
@media only screen and (max-width: 1263px) {
  .header-comms {
    justify-content: space-around;
  }
}
.header-comms .telComms {
  display: flex;
  font: 700 20px/32px "Noto Sans", sans-serif;
  padding: 5px 0;
}
.header-comms .telComms span {
  float: right;
  display: block;
  height: 32px;
}
.header-comms .telComms span.tele, .header-comms .telComms span.fax {
  width: 32px;
  margin-right: 10px;
}
.header-comms .telComms span.tele {
  background: url("/images/telIconBlu.png") no-repeat;
}
.header-comms .telComms span.fax {
  background: url("/images/faxIconBlu.png") no-repeat;
}

.logo-wrapper {
  display: flex;
  max-width: 720px;
}

.header-text {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.header-logo {
  background-image: url("/images/hdr_logo_100.png");
  background-repeat: no-repeat;
  background-size: contain;
  display: block;
  flex: 1 0 53px;
  height: 60px;
  margin-right: 10px;
  width: 87px;
}
@media only screen and (min-width: 600px) {
  .header-logo {
    height: 100px;
    width: 87px;
  }
}

/* Menus */
/* main menu */
.nav-wrapper {
  position: sticky;
  height: 42px;
  width: 100%;
  top: 0px;
  transition: box-shadow 0.2s ease, background 0.2s ease;
  background-color: #0063D3;
  z-index: 2;
  color: rgb(255, 255, 255);
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.5);
}
.nav-wrapper.floatAbove {
  position: fixed;
  top: 0px;
  left: 0px;
}

@media only screen and (max-width: 599px) {
  .main-nav {
    display: none;
  }
  .mobile-nav {
    transition: left 250ms cubic-bezier(0.4, 0, 0.2, 1) 0s;
    align-items: center;
    display: flex;
    height: 42px;
    position: relative;
    left: 0;
  }
  .mobile-nav.is-open {
    left: 230px;
  }
  .main-nav__tabs-wrapper {
    transition: height 250ms cubic-bezier(0.4, 0, 0.2, 1) 0s;
    height: 0;
    overflow: hidden;
  }
}
@media only screen and (min-width: 600px) {
  .mobile-nav {
    display: none;
  }
  .main-nav {
    font-family: "Noto Sans", sans-serif;
    font-size: 20px;
    line-height: 1;
    display: flex;
    flex-direction: row;
    height: 42px;
    position: relative;
  }
  .main-nav .main-nav__item {
    transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0s;
    background-color: rgba(0, 60, 128, 0);
    flex: 1 0;
    margin: 0;
    padding: 0;
  }
  .main-nav .main-nav__item:focus, .main-nav .main-nav__item:hover {
    background-color: rgb(0, 60, 128);
  }
  .main-nav .main-nav__item:focus .main-nav__tabs, .main-nav .main-nav__item:hover .main-nav__tabs {
    opacity: 1;
    pointer-events: all;
  }
  .main-nav .main-nav__item.is-linear {
    position: relative;
  }
  .main-nav .main-nav__label, .main-nav .main-nav__label:link, .main-nav .main-nav__label:visited {
    align-items: center;
    color: #fff;
    cursor: pointer;
    display: flex;
    font-family: "Noto Sans", sans-serif;
    font-size: 18px;
    font-weight: 700;
    height: 42px;
    justify-content: center;
    line-height: 1;
    padding: 0 8px;
    text-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
  }
}
@media only screen and (min-width: 600px) and (min-width: 960px) {
  .main-nav .main-nav__label, .main-nav .main-nav__label:link, .main-nav .main-nav__label:visited {
    font-size: 20px;
  }
}
@media only screen and (min-width: 600px) {
  .main-nav .tab-item {
    width: calc(25% - 10px);
  }
  .main-nav .tab-item a:link,
  .main-nav .tab-item a:visited {
    align-items: center;
    display: flex !important;
    font: 700 14px/20px "Noto Sans", sans-serif;
    height: 26px !important;
    padding: 3px !important;
    text-align: left;
  }
}
@media only screen and (min-width: 600px) {
  .main-nav__tabs {
    transition: all 250ms cubic-bezier(0.4, 0, 0.2, 1) 0s;
    border-radius: 0 0 4px 4px;
    background-color: rgb(16, 146, 195);
    background-color: rgb(0, 60, 128);
    box-shadow: 0 3px 2px -1px rgba(0, 0, 0, 0.5);
    display: flex;
    flex-wrap: wrap;
    left: 0px;
    opacity: 0;
    pointer-events: none;
    position: absolute;
    top: 42px;
    z-index: 2;
  }
  .is-linear .main-nav__tabs {
    flex-direction: column;
    min-width: 100%;
  }
}
@media only screen and (min-width: 600px) {
  .main-nav__tab-item {
    font-family: "Noto Sans", sans-serif;
    font-size: 16px;
    font-weight: 700;
    min-height: 26px;
    line-height: 20px;
    margin: 5px;
    width: calc(33% - 10px);
  }
}
@media only screen and (min-width: 600px) and (min-width: 960px) {
  .main-nav__tab-item {
    width: calc(25% - 10px);
  }
}
@media only screen and (min-width: 600px) {
  .is-linear .main-nav__tab-item {
    min-width: calc(100% - 10px);
  }
}
@media only screen and (min-width: 600px) {
  .main-nav__tab-link, .main-nav__tab-link:link, .main-nav__tab-link:visited {
    transition: background 250ms cubic-bezier(0.4, 0, 0.2, 1) 0s;
    align-items: center;
    background: rgba(0, 0, 0, 0);
    color: #fff;
    display: flex;
    min-height: 26px;
    padding: 3px 5px;
    width: calc(100% - 10px);
  }
  .main-nav__tab-link:focus, .main-nav__tab-link:hover {
    background: rgba(0, 0, 0, 0.5);
  }
  .is-linear .main-nav__tab-link {
    white-space: nowrap;
  }
}
.mobile-drawer {
  transition-delay: 0s;
  transition-duration: 250ms;
  transition-property: transform +", " +opacity;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  background: #fff;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  height: 100vh;
  opacity: 0;
  overflow: hidden;
  position: fixed;
  top: 0;
  transform: translateX(-230px);
  width: 220px;
  z-index: 10;
}
.mobile-drawer.is-open {
  opacity: 1;
  transform: translateX(0);
}

.mobile-drawer__header {
  box-sizing: border-box;
  display: block;
  height: 80px;
  padding: 10px;
  width: 220px;
}

.mobile-drawer__list {
  height: calc(100vh - 80px);
  overflow-y: auto;
  width: 100%;
  z-index: 2;
}

.mobile-drawer__offside {
  transition: opacity 250ms cubic-bezier(0.4, 0, 0.2, 1) 0s;
  background: rgba(0, 0, 0, 0.25);
  height: 100vh;
  opacity: 0;
  pointer-events: none;
  position: fixed;
  right: 0;
  top: 0;
  width: 100vw;
  z-index: 3;
}
.mobile-drawer__offside.is-visible {
  opacity: 1;
  pointer-events: all;
}

.hamburger-lines {
  appearance: none;
  background: none;
  border: 0 none;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  height: 18px;
  position: relative;
  justify-content: space-between;
  width: 30px;
  z-index: 2;
}
.hamburger-lines .line {
  transition: transform 0.2s cubic-bezier(0.4, 0, 0.2, 1) 0s;
  background: #fff;
  border-radius: 10px;
  display: block;
  height: 2px;
  width: 100%;
}
.hamburger-lines .line1 {
  transform-origin: 60% 0%;
}
.hamburger-lines .line3 {
  transform-origin: 60% 0%;
}
.hamburger-lines:hover .line1 {
  transform: rotate(45deg) scaleX(0.5) translateX(15px) translateY(-2px);
}
.hamburger-lines:hover .line3 {
  transform: rotate(-45deg) scaleX(0.5) translateX(14px) translateY(1px);
}

/* Mobile Menu */
.mobile-menu {
  font-family: "Noto Sans", sans-serif;
  line-height: 1;
  padding: 5px;
}

.mobile-menu__label, .mobile-menu__label:link, .mobile-menu__label:visited {
  transition: all 250ms cubic-bezier(0.4, 0, 0.2, 1) 0s;
  background: #fff;
  color: #000;
  cursor: pointer;
  display: block;
  font-size: 20px;
  height: 20px;
  padding: 5px;
  position: relative;
}
.mobile-menu__label:focus, .mobile-menu__label:hover, .mobile-menu__label.is-open {
  background: #0063D3;
  color: #fff;
}
.mobile-menu__label.has-children::before {
  transition: transform 250ms cubic-bezier(0.4, 0, 0.2, 1) 0s;
  border-bottom: 2px solid;
  border-right: 2px solid;
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  height: 10px;
  right: 10px;
  top: calc(50% - 7px);
  transform: rotate(45deg);
  transform-origin: 66% 69%;
  width: 10px;
}
.mobile-menu__label.has-children.is-open::before {
  transform: rotate(225deg);
}

.mobile-menu__children {
  display: block;
  padding-bottom: 5px;
}

.mobile-menu__children-wrapper {
  transition: height 250ms cubic-bezier(0.4, 0, 0.2, 1) 0s;
  height: 0;
  overflow: hidden;
}

.mobile-menu__children-item {
  padding: 5px 5px 0 15px;
}

.mobile-menu__children-label, .mobile-menu__children-label:link, .mobile-menu__children-label:visited {
  transition: all 250ms cubic-bezier(0.4, 0, 0.2, 1) 0s;
  background: #fff;
  color: #000;
  display: block;
  font-size: 16px;
  padding: 5px;
}
.mobile-menu__children-label:focus, .mobile-menu__children-label:hover {
  background: #0063D3;
  color: #fff;
}

ul#navBar {
  font: 700 20px/28px "Noto Sans", sans-serif;
  height: 42px;
  position: relative;
  margin: 0 10px;
}
ul#navBar > li {
  float: left;
  margin: 0;
  height: 42px;
  width: auto;
  min-width: 16%;
  text-align: left;
}
ul#navBar > li.standard {
  position: relative;
}
ul#navBar > li a,
ul#navBar > li p.blankLink {
  display: block;
  line-height: 42px;
  color: rgb(255, 255, 255);
  text-decoration: none;
  height: 42px;
  padding: 0 10px;
  text-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
}
ul#navBar > li a.home i {
  width: 35px;
  height: 40px;
  float: left;
  display: block;
  margin: 1px 10px 1px 0;
  background: url("./assets/images/hdr_logo_40_wht.png") no-repeat;
}
ul#navBar > li a:hover {
  background-color: rgb(16, 146, 195);
  background-color: rgb(0, 60, 128);
  cursor: pointer;
}
ul#navBar > li:hover a,
ul#navBar > li:hover p.blankLink {
  background-color: rgb(16, 146, 195);
  background-color: rgb(0, 60, 128);
  cursor: pointer;
}
ul#navBar li ul.tabs {
  position: absolute;
  top: 42px;
  left: 0px;
  width: auto;
  border-radius: 0 0 4px 4px;
  background-color: rgb(16, 146, 195);
  background-color: rgb(0, 60, 128);
  z-index: 2;
  box-shadow: 0 3px 2px -1px rgba(0, 0, 0, 0.5);
}
ul#navBar li ul.tabs.is-active {
  display: block;
}
ul#navBar li.subs ul.tabs li {
  margin: 5px;
  height: 26px;
  white-space: nowrap;
}
ul#navBar li#steelMenu ul.tabs {
  height: 150px;
  width: 940px;
  column-count: 4;
  -moz-column-count: 4;
  -webkit-column-count: 4;
}
ul#navBar li ul.tabs li a:hover {
  background: rgba(0, 0, 0, 0.15);
}

.slide-container {
  margin: 0 auto;
  position: relative;
  z-index: 1;
}
.slide-container .slides {
  display: block;
  overflow: hidden;
}
.slide-container .slide {
  height: 100%;
}
.slide-container .slide-image {
  max-height: 100%;
  height: 100%;
  min-width: 100%;
}

a:link, a:visited {
  outline: medium none;
  text-decoration: none;
  color: #0063D3;
}
a:hover {
  color: rgb(0, 60, 128);
  text-decoration: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  line-height: 1.4;
  margin: 0 0 8px;
  padding: 0;
}

h1 {
  font-size: 38px;
  font-family: "Noto Sans", sans-serif;
}
@media only screen and (max-width: 600px) {
  h1 {
    font-size: 30.4px;
  }
}
h1.tableHdr {
  font: 700 28px/29px "Noto Sans", sans-serif;
  margin-bottom: 20px;
}
h1.welcome {
  color: #0063D3;
  text-align: center;
  font: 700 44px/50px "Noto Sans", sans-serif;
  padding: 15px 0 20px;
}

h2 {
  font-size: 32px;
}
@media only screen and (max-width: 600px) {
  h2 {
    font-size: 25.6px;
  }
}

h3 {
  font-size: 26px;
}
@media only screen and (max-width: 600px) {
  h3 {
    font-size: 20.8px;
  }
}
h3.guideHdr {
  font: 500 20px/20px "Noto Sans", sans-serif;
}

h4 {
  font-size: 20px;
}
@media only screen and (max-width: 600px) {
  h4 {
    font-size: 16px;
  }
}

p {
  font-size: 18px;
  padding-bottom: 16px;
}
p.tblSpace {
  margin-bottom: 20px;
}
p.about {
  padding-bottom: 16px;
}

.hdrTopper h3 {
  color: #0063D3;
  font: 700 22px/22px "Noto Sans", sans-serif;
}
.hdrTopper h3 i {
  font-style: normal;
}

.text-center {
  text-align: center;
}

.table-wrapper {
  overflow-x: auto;
  overflow-y: hidden;
  max-width: 100%;
  padding-bottom: 6px;
}
.table-wrapper table {
  margin: 0 auto;
}
.table-wrapper table th {
  background-color: #fff;
}
.table-wrapper table td {
  background-color: #f0f0f0;
}

.table-notice {
  align-items: center;
  display: flex;
  justify-content: center;
}
.table-notice span {
  display: flex;
}
.table-notice span:nth-of-type(1), .table-notice span:nth-of-type(3) {
  font-size: 30px;
}
.table-notice span:nth-of-type(2) {
  max-width: 70vw;
  padding: 0 10px;
  text-align: center;
}
@media only screen and (max-width: 600px) {
  .table-notice span:nth-of-type(2) {
    max-width: 50vw;
  }
}
@media only screen and (max-width: 800px) {
  .table-notice span:nth-of-type(2) {
    max-width: 60vw;
  }
}

.table-100 {
  table-layout: fixed;
  width: calc(100% - 1px);
}
.table-100 td {
  padding: 0 4px;
}

.table-60 {
  table-layout: fixed;
  width: calc(60% - 1px);
}
.table-60 td {
  padding: 0 4px;
}

.table-full {
  min-width: 1060px;
}

.full-table {
  margin: 0 5px;
  min-width: 300px;
  width: calc(100% - 20px);
}

tbody,
tfoot,
thead,
tr,
th {
  border-color: rgba(0, 0, 0, 0.8);
}

td {
  border-color: rgba(0, 0, 0, 0.8);
  line-height: 26px;
}

th {
  line-height: 26px;
}

table.seven td {
  width: 100px;
}
table.ten td {
  width: 71px;
}
table td.blank {
  border-bottom-color: rgba(0, 0, 0, 0);
  border-top-color: rgba(0, 0, 0, 0);
}
table td.zero {
  border-color: rgba(0, 0, 0, 0);
}

.sticky-row {
  background-color: #fff;
  position: relative;
  z-index: 1;
}
.sticky-row th {
  background-color: #fff;
}

tbody {
  background-color: #e8e8e5;
  z-index: 1;
}
tbody.bordered {
  border: 1px solid rgba(0, 0, 0, 0.8);
}

/* Steel Tables */
.steelTable {
  border: 1px solid rgba(0, 0, 0, 0.8);
  border-bottom: none medium;
  width: 298px;
  position: relative;
  font: 500 12px/26px "Noto Sans", sans-serif;
}
.grid_d .steelTable {
  width: 458px;
}
.steelTable .tblHdr {
  font: 500 18px/26px "Noto Sans", sans-serif;
  height: 26px;
  background-color: rgba(0, 0, 0, 0.05);
  font-weight: 700;
  height: auto;
}
.steelTable .halfCell {
  width: 139px;
  padding: 0px 5px;
  float: left;
  display: block;
  border-bottom: 1px solid rgba(0, 0, 0, 0.8);
}
.steelTable .halfCell:nth-child(odd) {
  border-right: 1px solid rgba(0, 0, 0, 0.8);
  width: 138px;
}
.steelTable .tblLrgHdr {
  font: 500 14px/26px "Noto Sans", sans-serif;
  background-color: rgba(0, 0, 0, 0.05);
  font-weight: 700;
  height: auto;
}
.steelTable .fiveCell {
  text-align: center;
  width: 91px;
  width: 82px;
  padding: 0;
  float: left;
  display: block;
  border-right: 1px solid rgba(0, 0, 0, 0.8);
  border-bottom: 1px solid rgba(0, 0, 0, 0.8);
}
.steelTable .fiveCell.endCell {
  border-right: none medium;
  width: 90px;
  width: 126px;
}
.steelTable .fourCell.fourTall,
.steelTable .fiveCell.fourTall,
.steelTable .sixCell.fourTall,
.steelTable .sevenCell.fourTall,
.steelTable .eightCell.fourTall {
  height: 104px;
}
.steelTable .fourCell.threeTall {
  height: 80px;
}
.steelTable .fourCell.dbl {
  width: 114px;
}
.steelTable .fourCell.threeTall.dbl {
  width: 229px;
}
.steelTable .fourCell.threeTall em {
  font: 700 12px/53px "Noto Sans", sans-serif;
}
.steelTable .fiveCell.dbl {
  width: 165px;
}
.steelTable .sixCell.dbl {
  width: 153px;
}
.steelTable .sevenCell.dbl {
  width: 131px;
}
.steelTable .eightCell.dbl {
  width: 113px;
}
.steelTable .tblRow .halfCell {
  text-align: center;
}
.steelTable .tblRow .fiveCell,
.steelTable .tblRow .fourCell {
  height: 26px;
}
.steelTable .tblRow .eightCell {
  font-size: 11px;
}
.steelTable .sixCell,
.steelTable .sevenCell,
.steelTable .eightCell {
  height: 26px;
}
.steelTable .fiveCell.fourTall.dbl i,
.steelTable .sixCell.fourTall.dbl i,
.steelTable .sevenCell.fourTall.dbl i,
.steelTable .eightCell.fourTall.dbl i {
  font: 700 14px/26px "Noto Sans", sans-serif;
  height: 26px;
  display: block;
  border-bottom: 1px solid rgba(0, 0, 0, 0.8);
}
.steelTable .fourCell.fourTall.dbl span,
.steelTable .fiveCell.fourTall.dbl span,
.steelTable .sixCell.fourTall.dbl span,
.steelTable .sevenCell.fourTall.dbl span,
.steelTable .eightCell.fourTall.dbl span {
  float: left;
  width: 72px;
  padding: 0 5px;
  height: 78px;
}
.steelTable .fourCell.fourTall.dbl span {
  width: 47px;
  font: 700 12px/26px "Noto Sans", sans-serif;
}
.steelTable .fourCell.endCell.fourTall.dbl span {
  width: 46px;
}
.steelTable .sixCell.fourTall.dbl span {
  width: 66px;
}
.steelTable .sevenCell.fourTall.dbl span {
  width: 55px;
}
.steelTable .eightCell.fourTall.dbl span {
  width: 46px;
}
.steelTable .eightCell.fourTall.dbl span.topper {
  border-right: 1px solid rgba(0, 0, 0, 0.8);
  border-bottom: 1px solid rgba(0, 0, 0, 0.8);
  font-size: 10px;
  height: 26px;
}
.steelTable .eightCell.fourTall.dbl span.base {
  border-right: 1px solid rgba(0, 0, 0, 0.8);
  font-size: 9px;
  height: 50px;
}
.steelTable .eightCell.fourTall.dbl span.topper.dbl {
  width: 103px;
}
.steelTable .eightCell.fourTall.dbl span.topper.last {
  border-right: none medium;
  width: 49px;
}
.steelTable .eightCell.fourTall.dbl span.base.last {
  border-right: none medium;
  width: 49px;
}
.steelTable .fourCell.threeTall.dbl span.first,
.steelTable .fiveCell.fourTall.dbl span.first,
.steelTable .sixCell.fourTall.dbl span.first,
.steelTable .sevenCell.fourTall.dbl span.first,
.steelTable .eightCell.fourTall.dbl span.first {
  border-right: 1px solid rgba(0, 0, 0, 0.8);
}
.steelTable .fourCell {
  text-align: center;
  width: 114px;
  padding: 0;
  float: left;
  display: block;
  border-right: 1px solid rgba(0, 0, 0, 0.8);
  border-bottom: 1px solid rgba(0, 0, 0, 0.8);
}
.steelTable .fourCell.threeTall span.base {
  border-top: 1px solid rgba(0, 0, 0, 0.8);
}
.steelTable .fourCell.endCell {
  border-right: none medium;
  width: 113px;
}
.steelTable .fourCell i {
  font: 700 12px/26px "Noto Sans", sans-serif;
  height: 26px;
  display: block;
  border-bottom: 1px solid rgba(0, 0, 0, 0.8);
}
.steelTable .fourCell span {
  width: 104px;
  height: 67px;
  padding: 5px;
  display: block;
  font: 700 14px/20px "Noto Sans", sans-serif;
}
.steelTable .fourCell.threeTall span {
  font: 700 11.5px/26px "Noto Sans", sans-serif;
  width: 104px;
  height: 26px;
  padding: 0 5px;
  float: left;
}
.steelTable .fourCell span.split {
  float: left;
  width: 57px;
  height: 26px;
  padding: 0;
  font: 500 12px/26px "Noto Sans", sans-serif;
}
.steelTable .fourCell.endCell span.split {
  width: 56px;
}
.steelTable .sixCell {
  text-align: center;
  width: 76px;
  padding: 0;
  float: left;
  display: block;
  border-right: 1px solid rgba(0, 0, 0, 0.8);
  border-bottom: 1px solid rgba(0, 0, 0, 0.8);
}
.steelTable .sixCell.endCell {
  border-right: none medium;
  width: 73px;
}
.steelTable .sixCell.dbl.endCell {
  width: 150px;
}
.steelTable .sixCell.fourTall.dbl.endCell span {
  height: 50px;
  width: 63px;
}
.steelTable .sixCell.fourTall.dbl.endCell span.first {
  width: 66px;
}
.steelTable .sixCell.fourTall.dbl.endCell i {
  height: 53px;
}
.steelTable .sevenCell {
  text-align: center;
  width: 65px;
  padding: 0;
  float: left;
  display: block;
  border-right: 1px solid rgba(0, 0, 0, 0.8);
  border-bottom: 1px solid rgba(0, 0, 0, 0.8);
}
.steelTable .sevenCell.endCell {
  border-right: none medium;
  width: 62px;
}
.steelTable .tblLrgHdr .sevenCell.endCell {
  width: 326px;
}
.steelTable .eightCell {
  text-align: center;
  width: 56px;
  padding: 0;
  float: left;
  display: block;
  border-right: 1px solid rgba(0, 0, 0, 0.8);
  border-bottom: 1px solid rgba(0, 0, 0, 0.8);
}
.steelTable .eightCell.endCell {
  border-right: none medium;
  width: 59px;
}
.steelTable .tblLrgHdr .eightCell,
.steelTable .eightCell.fourTall.dbl i {
  font-size: 12.5px !important;
}
.steelTable .tblLrgHdr .eightCell.endCell {
  width: 344px;
}

.thin-font {
  font-weight: 100;
}

.theme--light.v-application,
.theme--dark.v-application {
  background: rgba(245, 245, 240, 0.75) !important;
  color: rgb(0, 0, 0) !important;
}

.v-application ul {
  padding-left: 0;
}