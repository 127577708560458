$background-color_1: rgba(0, 0, 0, 0.05);

.table-wrapper {
  overflow-x: auto;
  overflow-y: hidden;
  max-width: 100%;
  padding-bottom: 6px;

  table {
    margin: 0 auto;

    th {
      background-color: #fff;
    }

    td {
      background-color: #f0f0f0;
    }
  }
}

.table-notice {
  align-items: center;
  display: flex;
  justify-content: center;

  span {
    display: flex;

    &:nth-of-type(1),
    &:nth-of-type(3) {
      font-size: 30px;
    }

    &:nth-of-type(2) {
      max-width: 70vw;
      padding: 0 10px;
      text-align: center;

      @media only screen and (max-width: 600px) {
        max-width: 50vw;
      }

      @media only screen and (max-width: 800px) {
        max-width: 60vw;
      }
    }
  }
}

.table-100 {
  table-layout: fixed;
  width: calc(100% - 1px);

  td {
    padding: 0 4px;
  }
}

.table-60 {
  table-layout: fixed;
  width: calc(60% - 1px);

  td {
    padding: 0 4px;
  }
}

.table-full {
  min-width: calc($site-width - 60px);
}

.full-table {
  margin: 0 5px;
  min-width: 300px;
  width: calc(100% - 20px);
}

tbody,
tfoot,
thead,
tr,
th {
  border-color: rgba(0, 0, 0, 0.8);
}

td {
  border-color: rgba(0, 0, 0, 0.8);
  line-height: 26px;
}

th {
  line-height: 26px;
}

table {
  &.seven td {
    width: 100px;
  }

  &.ten td {
    width: 71px;
  }

  td {
    &.blank {
      border-bottom-color: rgba(0, 0, 0, 0);
      border-top-color: rgba(0, 0, 0, 0);
    }

    &.zero {
      border-color: rgba(0, 0, 0, 0);
    }
  }
}

.sticky-row {
  background-color: #fff;
  position: relative;
  z-index: 1;

  th {
    background-color: #fff;
  }
}

tbody {
  background-color: #e8e8e5;
  z-index: 1;

  &.bordered {
    border: 1px solid rgba(0, 0, 0, 0.8);
  }
}


/* Steel Tables */
.steelTable {
  border: 1px solid rgba(0, 0, 0, 0.8);
  border-bottom: none medium;
  width: 298px;
  position: relative;
  font: 500 12px/26px 'Noto Sans', sans-serif;

  .grid_d & {
    width: 458px;
  }

  .tblHdr {
    font: 500 18px/26px 'Noto Sans', sans-serif;
    height: 26px;
    background-color: rgba(0, 0, 0, 0.05);
    font-weight: 700;
    height: auto;
  }

  .halfCell {
    width: 139px;
    padding: 0px 5px;
    float: left;
    display: block;
    border-bottom: 1px solid rgba(0, 0, 0, 0.8);

    &:nth-child(odd) {
      border-right: 1px solid rgba(0, 0, 0, 0.8);
      width: 138px;
    }
  }

  .tblLrgHdr {
    font: 500 14px/26px 'Noto Sans', sans-serif;
    background-color: rgba(0, 0, 0, 0.05);
    font-weight: 700;
    height: auto;
  }

  .fiveCell {
    text-align: center;
    width: 91px;
    width: 82px;
    padding: 0;
    float: left;
    display: block;
    border-right: 1px solid rgba(0, 0, 0, 0.8);
    border-bottom: 1px solid rgba(0, 0, 0, 0.8);

    &.endCell {
      border-right: none medium;
      width: 90px;
      width: 126px;
    }
  }

  .fourCell.fourTall,
  .fiveCell.fourTall,
  .sixCell.fourTall,
  .sevenCell.fourTall,
  .eightCell.fourTall {
    height: 104px;
  }

  .fourCell {
    &.threeTall {
      height: 80px;
    }

    &.dbl {
      width: 114px;
    }

    &.threeTall {
      &.dbl {
        width: 229px;
      }

      em {
        font: 700 12px/53px 'Noto Sans', sans-serif;
      }
    }
  }

  .fiveCell.dbl {
    width: 165px;
  }

  .sixCell.dbl {
    width: 153px;
  }

  .sevenCell.dbl {
    width: 131px;
  }

  .eightCell.dbl {
    width: 113px;
  }

  .tblRow {
    .halfCell {
      text-align: center;
    }

    .fiveCell,
    .fourCell {
      height: 26px;
    }

    .eightCell {
      font-size: 11px;
    }
  }

  .sixCell,
  .sevenCell,
  .eightCell {
    height: 26px;
  }

  .fiveCell.fourTall.dbl i,
  .sixCell.fourTall.dbl i,
  .sevenCell.fourTall.dbl i,
  .eightCell.fourTall.dbl i {
    font: 700 14px/26px 'Noto Sans', sans-serif;
    height: 26px;
    display: block;
    border-bottom: 1px solid rgba(0, 0, 0, 0.8);
  }

  .fourCell.fourTall.dbl span,
  .fiveCell.fourTall.dbl span,
  .sixCell.fourTall.dbl span,
  .sevenCell.fourTall.dbl span,
  .eightCell.fourTall.dbl span {
    float: left;
    width: 72px;
    padding: 0 5px;
    height: 78px;
  }

  .fourCell {
    &.fourTall.dbl span {
      width: 47px;
      font: 700 12px/26px 'Noto Sans', sans-serif;
    }

    &.endCell.fourTall.dbl span {
      width: 46px;
    }
  }

  .sixCell.fourTall.dbl span {
    width: 66px;
  }

  .sevenCell.fourTall.dbl span {
    width: 55px;
  }

  .eightCell.fourTall.dbl span {
    width: 46px;

    &.topper {
      border-right: 1px solid rgba(0, 0, 0, 0.8);
      border-bottom: 1px solid rgba(0, 0, 0, 0.8);
      font-size: 10px;
      height: 26px;
    }

    &.base {
      border-right: 1px solid rgba(0, 0, 0, 0.8);
      font-size: 9px;
      height: 50px;
    }

    &.topper {
      &.dbl {
        width: 103px;
      }

      &.last {
        border-right: none medium;
        width: 49px;
      }
    }

    &.base.last {
      border-right: none medium;
      width: 49px;
    }
  }

  .fourCell.threeTall.dbl span.first,
  .fiveCell.fourTall.dbl span.first,
  .sixCell.fourTall.dbl span.first,
  .sevenCell.fourTall.dbl span.first,
  .eightCell.fourTall.dbl span.first {
    border-right: 1px solid rgba(0, 0, 0, 0.8);
  }

  .fourCell {
    &.threeTall span.base {
      border-top: 1px solid rgba(0, 0, 0, 0.8);
    }

    text-align: center;
    width: 114px;
    padding: 0;
    float: left;
    display: block;
    border-right: 1px solid rgba(0, 0, 0, 0.8);
    border-bottom: 1px solid rgba(0, 0, 0, 0.8);

    &.endCell {
      border-right: none medium;
      width: 113px;
    }

    i {
      font: 700 12px/26px 'Noto Sans', sans-serif;
      height: 26px;
      display: block;
      border-bottom: 1px solid rgba(0, 0, 0, 0.8);
    }

    span {
      width: 104px;
      height: 67px;
      padding: 5px;
      display: block;
      font: 700 14px/20px 'Noto Sans', sans-serif;
    }

    &.threeTall span {
      font: 700 11.5px/26px 'Noto Sans', sans-serif;
      width: 104px;
      height: 26px;
      padding: 0 5px;
      float: left;
    }

    span.split {
      float: left;
      width: 57px;
      height: 26px;
      padding: 0;
      font: 500 12px/26px 'Noto Sans', sans-serif;
    }

    &.endCell span.split {
      width: 56px;
    }
  }

  .sixCell {
    text-align: center;
    width: 76px;
    padding: 0;
    float: left;
    display: block;
    border-right: 1px solid rgba(0, 0, 0, 0.8);
    border-bottom: 1px solid rgba(0, 0, 0, 0.8);

    &.endCell {
      border-right: none medium;
      width: 73px;
    }

    &.dbl.endCell {
      width: 150px;
    }

    &.fourTall.dbl.endCell {
      span {
        height: 50px;
        width: 63px;

        &.first {
          width: 66px;
        }
      }

      i {
        height: 53px;
      }
    }
  }

  .sevenCell {
    text-align: center;
    width: 65px;
    padding: 0;
    float: left;
    display: block;
    border-right: 1px solid rgba(0, 0, 0, 0.8);
    border-bottom: 1px solid rgba(0, 0, 0, 0.8);

    &.endCell {
      border-right: none medium;
      width: 62px;
    }
  }

  .tblLrgHdr .sevenCell.endCell {
    width: 326px;
  }

  .eightCell {
    text-align: center;
    width: 56px;
    padding: 0;
    float: left;
    display: block;
    border-right: 1px solid rgba(0, 0, 0, 0.8);
    border-bottom: 1px solid rgba(0, 0, 0, 0.8);

    &.endCell {
      border-right: none medium;
      width: 59px;
    }
  }

  .tblLrgHdr .eightCell,
  .eightCell.fourTall.dbl i {
    font-size: 12.5px !important;
  }

  .tblLrgHdr .eightCell.endCell {
    width: 344px;
  }
}

.thin-font {
  font-weight: 100;
}