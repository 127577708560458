//================================================================================================//
//
//  MEDIAQUERY TOOLS
//  N.B: Only mixins here NEVER anything that outputs CSS directly
//
//================================================================================================//

// VARS
$media-xxs: 340;
$media-xs: 340;
$media-sm: 600;
$media-md: 960;
$media-lg: 1264;
$media-xl: 1904;

// misc
// $media-menu: 768;
$media-menu: 600;

// MIXINS
@mixin media-max-menu {
  @media only screen and (max-width: #{$media-menu - 1}px) {
    @content;
  }
}

@mixin media-min-menu {
  @media only screen and (min-width: #{$media-menu}px) {
    @content;
  }
}

@mixin media-max-xxs {
  @media only screen and (max-width: #{$media-xxs - 1}px) {
    @content;
  }
}

@mixin media-min-xs {
  @media only screen and (min-width: #{$media-xs}px) {
    @content;
  }
}

@mixin media-max-xs {
  @media only screen and (max-width: #{$media-xs - 1}px) {
    @content;
  }
}

@mixin media-min-xs-max-sm {
  @media only screen and (min-width: #{$media-xs}px) and (max-width: #{$media-sm - 1}px) {
    @content;
  }
}

@mixin media-min-sm {
  @media only screen and (min-width: #{$media-sm}px) {
    @content;
  }
}

@mixin media-max-sm {
  @media only screen and (max-width: #{$media-sm - 1}px) {
    @content;
  }
}

@mixin media-min-sm-max-md {
  @media only screen and (min-width: #{$media-sm}px) and (max-width: #{$media-md - 1}px) {
    @content;
  }
}

@mixin media-min-sm-max-lg {
  @media only screen and (min-width: #{$media-sm}px) and (max-width: #{$media-lg - 1}px) {
    @content;
  }
}

@mixin media-max-md {
  @media only screen and (max-width: #{$media-lg - 1}px) {
    @content;
  }
}

@mixin media-min-md {
  @media only screen and (min-width: #{$media-md}px) {
    @content;
  }
}

@mixin media-min-md-max-lg {
  @media only screen and (min-width: #{$media-md}px) and (max-width: #{$media-lg - 1}px) {
    @content;
  }
}

@mixin media-min-lg {
  @media only screen and (min-width: #{$media-lg}px) {
    @content;
  }
}

@mixin media-min-xl {
  @media only screen and (min-width: #{$media-xl}px) {
    @content;
  }
}

/* stylelint-disable media-feature-name-no-unknown */
@mixin retina {
  @media
    only screen and (-webkit-min-device-pixel-ratio: 2),
    only screen and (min--moz-device-pixel-ratio: 2),
    only screen and (-o-min-device-pixel-ratio: 2/1),
    only screen and (min-device-pixel-ratio: 2),
    only screen and (min-resolution: 192dpi),
    only screen and (min-resolution: 2dppx) {
    @content;
  }
}
/* stylelint-enable media-feature-name-no-unknown */

@mixin print {
  @media print {
    @content;
  }
}
