// @import url('https://fonts.googleapis.com/css2?family=Noto+Sans:wght@100;400;600&display=swap');

@font-face {
  font-family: 'Noto Sans';
  font-style: normal;
  font-weight: 100;
  src: local('Noto Sans Thin'), local('Noto Sans Thin'), url('/assets/fonts/NotoSans-Thin.woff') format('woff');
}

@font-face {
  font-family: 'Noto Sans';
  font-style: normal;
  font-weight: 400;
  src: local('Noto Sans Medium'), local('Noto Sans Medium'), url('/assets/fonts/NotoSans-Medium.woff') format('woff');
}

@font-face {
  font-family: 'Noto Sans';
  font-style: normal;
  font-weight: 500;
  src: local('Noto Sans Regular'), local('Noto Sans Regular'), url('/assets/fonts/NotoSans-Regular.woff') format('woff');
}

@font-face {
  font-family: 'Noto Sans';
  font-style: normal;
  font-weight: 700;
  src: local('Noto Sans Bold'), local('Noto Sans Bold'), url('/assets/fonts/NotoSans-Bold.woff') format('woff');
}

@font-face {
  font-family: 'Christmas Century';
  font-style: normal;
  font-weight: 400;
  src: local('Christmas Century'), local('Christmas Century'), url('/assets/fonts/NewCenturySchlbkRoman.woff') format('woff');
}

@font-face {
  font-family: 'Christmas Century';
  font-style: normal;
  font-weight: 700;
  src: local('Christmas Century Bold'), local('Christmas Century Bold'), url('/assets/fonts/NewCenturySchlbkBold.woff') format('woff');
}
