.main-footer {
  color: #1b1b1b;
  padding: 0;
  width: 100%;
  z-index: 2;
  transition: all .2s ease;
  background-color: rgba(208, 208, 208, 0.9);

  > .container {
    background-color: rgba(208, 208, 208, 1);
  }

  .footer-address {
    font-size: 20px;
  }
}

.sub-footer {
  align-items: center;
  background-color: #1b1b1b;;
  color: rgba(208, 208, 208, 0.9);
  display: flex;
  height: 30px;
  justify-content: flex-end;

  .copyright {
    text-align: right;
  }
}
