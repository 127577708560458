a {

  &:link,
  &:visited {
    outline: medium none;
    text-decoration: none;
    color: $color-brand-blue; // rgba(1, 124, 221, 1);
  }

  &:hover {
    color: $color-brand-blue-hover; // rgba(0, 174, 239, 1);
    text-decoration: none;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  line-height: 1.4;
  margin: 0 0 8px;
  padding: 0;
}

h1 {
  font-size: $fs-hdr-1; // 28px;
  font-family: 'Noto Sans', sans-serif;

  @media only screen and (max-width: 600px) {
    font-size: calc($fs-hdr-1 * $fs-multi);
  }

  &.tableHdr {
    font: 700 28px/29px 'Noto Sans', sans-serif;
    margin-bottom: 20px;
  }

  &.welcome {
    color: $color-brand-blue;
    text-align: center;
    font: 700 44px/50px 'Noto Sans', sans-serif;
    padding: 15px 0 20px;
  }
}

h2 {
  font-size: $fs-hdr-2; // 24px;

  @media only screen and (max-width: 600px) {
    font-size: calc($fs-hdr-2 * $fs-multi);
  }
}

h3 {
  font-size: $fs-hdr-3; // 20px;

  @media only screen and (max-width: 600px) {
    font-size: calc($fs-hdr-3 * $fs-multi);
  }

  &.guideHdr {
    font: 500 20px/20px 'Noto Sans', sans-serif;
  }
}

h4 {
  font-size: $fs-hdr-4; // 20px;

  @media only screen and (max-width: 600px) {
    font-size: calc($fs-hdr-4 * $fs-multi);
  }
}

p {
  font-size: 18px;
  padding-bottom: 16px;

  &.tblSpace {
    margin-bottom: 20px;
  }

  &.about {
    padding-bottom: 16px;
  }
}

.hdrTopper h3 {
  color: $color-brand-blue;
  font: 700 22px/22px 'Noto Sans', sans-serif;

  i {
    font-style: normal;
  }
}

.text-center {
  text-align: center;
}
