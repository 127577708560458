// Example usage: @include animate(left top);
@mixin animate($properties: all, $duration: $animation-speed, $easing: $easing, $delay: 0s) {

  @if $properties ==transform {
    transition: transform $duration $easing $delay;
  }

  @else {
    @if length($properties)>1 {
      $new-properties: unquote('');

      @for $i from 1 through length($properties) {
        $p: nth($properties, $i);

        @if($i ==length($properties)) {
          $new-properties: $new-properties (+$p);
        }

        @else {
          $new-properties: $new-properties + $p (+', ');
        }
      }

      transition-delay: $delay;
      transition-duration: $duration;
      transition-property: $new-properties;
      transition-timing-function: $easing;
    }

    @else {
      transition: $properties $duration $easing $delay;
    }
  }
}

// Example usage: @include keyframes( animation ) { scss };
@mixin keyframes($name) {
  @-webkit-keyframes #{$name} {
    @content;
  }

  @-moz-keyframes #{$name} {
    @content;
  }

  @-ms-keyframes #{$name} {
    @content;
  }

  @keyframes #{$name} {
    @content;
  }
}

@mixin fill {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}

@mixin clearfix() {
  &::after {
    clear: both;
    content: '';
    display: block;
  }
}

@mixin aspect($width, $height) {
  $percentage: calc(1 / ($width / $height));
  position: relative;
  height: 0;
  padding-bottom: percentage($number: $percentage);
  width: 100%;
}

@mixin gradient($start-color, $end-color, $orientation) {
  background: $start-color;

  @if $orientation =='vertical' {
    background: -webkit-linear-gradient(top, $start-color, $end-color);
    background: linear-gradient(to bottom, $start-color, $end-color);
  }

  @else if $orientation =='horizontal' {
    background: -webkit-linear-gradient(left, $start-color, $end-color);
    background: linear-gradient(to right, $start-color, $end-color);
  }

  @else {
    background: -webkit-radial-gradient(center, ellipse cover, $start-color, $end-color);
    background: radial-gradient(ellipse at center, $start-color, $end-color);
  }
}

// Example usage: @include triangle-down( 10px, 10px, #000 );
@mixin triangle-down($left-right: 10px, $top: 10px, $colour: #000) {
  border-left: $left-right solid transparent;
  border-right: $left-right solid transparent;
  border-top: $top solid $colour;
  border-bottom: none medium;
  display: block;
  height: 0;
  width: 0;
}