$spaceamounts: (0, 4, 8, 12, 16, 20, 24, 28, 32, 36, 40);

$sides: (top, bottom, left, right);

@each $space in $spaceamounts {
  $i: index($spaceamounts, $space) - 1;

  @each $side in $sides {
    .m#{str-slice($side, 0, 1)}-#{$i} {
      margin-#{$side}: #{$space}px  !important;
    }

    .m#{str-slice($side, 0, 1)}-n#{$i} {
      margin-#{$side}: -#{$space}px !important;
    }

    .p#{str-slice($side, 0, 1)}-#{$i} {
      padding-#{$side}: #{$space}px !important;
    }
  }

  .ma-#{$i} {
    margin: #{$space}px !important;
  }

  .ma-n#{$i} {
    margin: -#{$space}px !important;
  }

  .mx-#{$i} {
    margin-left: #{$space}px !important;
    margin-right: #{$space}px !important;
  }

  .my-#{$i} {
    margin-bottom: #{$space}px !important;
    margin-top: #{$space}px !important;
  }

  .mx-n#{$i} {
    margin-left: -#{$space}px !important;
    margin-right: -#{$space}px !important;
  }

  .my-n#{$i} {
    margin-bottom: -#{$space}px !important;
    margin-top: -#{$space}px !important;
  }

  .pa-#{$i} {
    padding: #{$space}px !important;
  }

  .px-#{$i} {
    padding-left: #{$space}px !important;
    padding-right: #{$space}px !important;
  }

  .py-#{$i} {
    padding-bottom: #{$space}px !important;
    padding-top: #{$space}px !important;
  }
}