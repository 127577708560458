.btn {
  @include animate(all);
  appearance: none;
  background-color: rgba(0, 0, 0, 0);
  border: 0 none;
  cursor: pointer;
  display: inline-flex;
  padding: 0;
  margin: 0;
}

.btn--icon {
  border-radius: 16px;
  height: 32px;
  width: 32px;
}

/* */
.btn-close-menu {
  position: absolute;
  right: 5px;
  top: 5px;

  &::before,
  &::after {
    background-color: #333;
    content: ' ';
    height: 22px;
    left: 15px;
    position: absolute;
    top: 5px;
    width: 2px;
  }

  &::before {
    transform: rotate(45deg);
  }
  &::after {
    transform: rotate(-45deg);
  }

  &:focus,
  &:hover {
    background-color: rgba(0, 0, 0, .1);
  }
}
