/* CSS Document */
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
font,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend {
  background: none repeat scroll 0 0 transparent;
  border: 0 none;
  font-size: 100%;
  margin: 0;
  outline: 0 none;
  padding: 0;
  vertical-align: baseline;
  -webkit-text-size-adjust: none;
}

input,
textarea,
button {
  appearance: none;
}

input:focus,
textarea:focus {
  appearance: none;
  outline: none;
}

ol,
ul {
  list-style: none outside none;
}

blockquote,
q {
  quotes: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
  border-color: rgba(0, 0, 0, 0.8);
}

/*, table, caption, tbody, tfoot, thead, tr, th, td
*/

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

* {
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;

  &:before,
  &:after {
    -webkit-box-sizing: content-box;
    -moz-box-sizing: content-box;
    box-sizing: content-box;
  }
}

body {
  // font: normal 12px/14px 'Noto Sans', sans-serif;
  // font: normal 12px/14px 'Noto Sans', sans-serif;
  font-family: 'Noto Sans', sans-serif;
  background-color: #F5F5F0;
  color: #181818;
  font-size: $fs-normal;
  line-height: 1.4;
  margin: 0px;
  padding: 0px;
  position: relative;
  height: 100%;
}

/* 960 Grid */

.container {
  box-sizing: border-box;
  max-width: $site-width;
  margin: 0 auto;
  padding: 0;
  width: 100%;
}

.grid-wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.grid_a {
  width: 100%;
  padding-bottom: 10px;

  @include media-min-sm() {
    flex: 1 0 calc(100% / 3);
    padding: 0;
    max-width: calc(100% / 3);
  }
}

.grid_b {
  width: 100%;
  padding-bottom: 10px;

  @include media-min-sm() {
    flex: 1 0 calc(100% - (100% / 3));
    padding: 0;
    max-width: calc(100% - (100% / 3));
  }
}

.grid_d {
  width: 100%;
  padding-bottom: 10px;

  @include media-min-md() {
    max-width: 460px;
    padding: 0;
  }
}

.grid_c {
  width: 100%;
  max-width: 940px;
}

/* set up */
.site-wrapper {
  max-width: 100vw;
}

.responsive-image {
  max-width: 100%;
  height: auto;
  width: 100%;
}

#header {
  position: fixed;
  position: relative;
  top: 0px;
  left: 0px;
  width: 100%;
  z-index: 1000;
  min-height: 162px;
}

.hdrTopper {
  height: 120px;
  background-color: rgba(255, 255, 255, 1);
  display: block;

  h1 {
    color: $color-brand-blue;
    font: 700 32px/29px "Christmas Century", "Times New Roman", serif;
    padding-top: 30px;
  }
}

#header {
  .logoWrapper {
    float: left;
    display: block;
    width: 720px;
  }

  .hdrComms {
    float: right;
    width: 180px;
    display: block;
    padding-top: 5px;
    color: $color-brand-blue;
    text-align: right;

    .telComms {
      font: 700 20px/32px 'Noto Sans', sans-serif;
      padding: 5px 0;

      span {
        float: right;
        display: block;
        height: 32px;

        &.tele,
        &.fax {
          width: 32px;
          margin-right: 10px;
        }

        &.tele {
          background: url('./assets/images/telIconBlu.png') no-repeat;
        }

        &.fax {
          background: url('./assets/images/faxIconBlu.png') no-repeat;
        }
      }
    }
  }

  .navHolder {
    position: relative;
    height: 42px;
    width: 100%;
    transition: box-shadow .2s ease, background .2s ease;
    background-color: $color-brand-blue;
    z-index: 999;
    color: rgba(255, 255, 255, 1);
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.5);

    &.floatAbove {
      position: fixed;
      top: 0px;
      left: 0px;
    }
  }
}

body {
  background-image: url('/images/bg002.jpg');
  background-repeat: no-repeat;
  background-position: top center;
  background-attachment: fixed;
  background-size: cover;
}

main {
  background-color: rgba(245, 245, 240, 0.75);

  .container {
    background-color: rgba(245, 245, 240, 0.75);
    box-sizing: border-box;
    min-height: 440px;
    padding: 0 12px;
  }
}

#googlemap {
  width: 620px;
  height: 400px;
}

/* Steel Tables */

#tblHolder {
  position: relative;
  margin: 20px 0 50px;
  margin: 20px 0 0;

  .grid_c {
    display: none;
  }
}

.halfCell {
  text-align: center;
}

.mitreGuide {
  position: relative;

  @include media-max-sm {
    align-items: center;
    display: flex;
    flex-direction: column;
  }

  .mitreInfo {
    width: 300px;
    height: 26px;

    @include media-max-sm {
      position: relative;
    }

    i {
      position: absolute;
      left: 0;
      top: 26px;
      font: 700 20px/20px 'Noto Sans', sans-serif;
    }

    p {
      font: 500 12px/20px 'Noto Sans', sans-serif;
      text-align: center;

      span {
        font: 500 9px/10px 'Noto Sans', sans-serif;
        text-align: center;
        display: block;
        margin-top: -4px;
      }
    }
  }

  .holder {
    margin: 0 0 0 15px;
    width: 285px;
    height: 64px;

    @include media-max-sm {
      margin: 0;
    }

    span {
      background: url('/images/mitre-guide.png') no-repeat;
      width: 285px;
      height: 64px;
      display: block;

      &.a1 {
        background-position: 0 0;
      }

      &.a2 {
        background-position: 0 -66px;
      }

      &.a3 {
        background-position: 0 -132px;
      }

      &.a4 {
        background-position: 0 -198px;
      }

      &.a5 {
        background-position: 0 -264px;
      }

      &.a6 {
        background-position: 0 -330px;
      }

      &.a7 {
        background-position: 0 -396px;
      }

      &.a8 {
        background-position: 0 -462px;
      }

      &.a9 {
        background-position: 0 -528px;
      }

      &.a10 {
        background-position: 0 -594px;
      }

      &.a11 {
        background-position: 0 -660px;
      }

      &.a12 {
        background-position: 0 -726px;
      }

      &.a13 {
        background-position: 0 -792px;
      }

      &.a14 {
        background-position: 0 -858px;
      }

      &.a15 {
        background-position: 0 -924px;
      }

      &.a16 {
        background-position: 0 -990px;
      }

      &.a17 {
        background-position: 0 -1056px;
      }

      &.a18 {
        background-position: 0 -1122px;
      }

      &.a19 {
        background-position: 0 -1188px;
      }

      &.a20 {
        background-position: 0 -1254px;
      }

      &.a21 {
        background-position: 0 -1320px;
      }

      &.a22 {
        background-position: 0 -1386px;
      }

      &.a23 {
        background-position: 0 -1452px;
      }

      &.a24 {
        background-position: 0 -1518px;
      }

      &.a25 {
        background-position: 0 -1584px;
      }

      &.a26 {
        background-position: 0 -1650px;
      }

      &.a27 {
        background-position: 0 -1716px;
      }

      &.a28 {
        background-position: 0 -1782px;
      }

      &.a29 {
        background-position: 0 -1848px;
      }

      &.a30 {
        background-position: 0 -1914px;
      }

      &.a31 {
        background-position: 0 -1980px;
      }

      &.a32 {
        background-position: 0 -2046px;
      }

      &.a33 {
        background-position: 0 -2112px;
      }

      &.a34 {
        background-position: 0 -2178px;
      }

      &.a35 {
        background-position: 0 -2244px;
      }

      &.a36 {
        background-position: 0 -2310px;
      }

      &.a37 {
        background-position: 0 -2376px;
      }

      &.a38 {
        background-position: 0 -2442px;
      }

      &.a39 {
        background-position: 0 -2508px;
      }

      &.a40 {
        background-position: 0 -2574px;
      }

      &.a41 {
        background-position: 0 -2640px;
      }

      &.a42 {
        background-position: 0 -2706px;
      }

      &.a43 {
        background-position: 0 -2772px;
      }

      &.a44 {
        background-position: 0 -2838px;
      }
    }
  }
}

.baseSpace {
  height: 30px;
  display: block;
}

.steelbookDownload {
  align-items: center;
  display: flex;
  font-size: 22px;
  margin-bottom: 20px;

  i {
    float: left;
    display: block;
    width: 46px;
    height: 46px;
    margin-right: 10px;
    background: url('/images/pdfIcon.png') no-repeat;
  }
}

.legalInfoBase {
  font-weight: 400;
}

img {
  &.about {
    display: inline-block;
    margin-bottom: 16px;

    @include media-max-sm() {
      max-width: 100%;
      width: 100%;
    }

    @include media-min-sm() {
      &.left {
        float: left;
        margin-right: 16px;
      }

      &.right {
        float: right;
        margin-left: 16px;
      }
    }
  }
}

ol {
  &.numbered-list {
    counter-reset: list;

    & > li {
      list-style: none;
      padding-bottom: 8px;
    }
    & > li:before {
        content: "(" counter(list) ") ";
        counter-increment: list;
    }
  }
}

ul {
  &.techInfo {
    padding: 5px 0 5px 20px;
  }
}

.slider {
  margin: 0 auto;
  padding: 0;
  width: 700px;

  /* Update to your slider width */
  height: 467px;

  /* Update to your slider height */
  position: relative;
  overflow: hidden;
  border: 4px solid rgba(0, 0, 0, 0.5);
  border-radius: $border-radius;
}

#slides {
  display: none;

  .slidesjs-navigation {
    margin-top: 5px;
  }
}

a {

  &.slidesjs-next,
  &.slidesjs-previous,
  &.slidesjs-play,
  &.slidesjs-stop {
    background-image: url('/images/btns-next-prev.png');
    background-repeat: no-repeat;
    display: block;
    width: 12px;
    height: 18px;
    overflow: hidden;
    text-indent: -9999px;
    float: left;
    margin-right: 5px;
  }

  &.slidesjs-next {
    margin-right: 10px;
    background-position: -12px 0;
  }

  &:hover.slidesjs-next {
    background-position: -12px -18px;
  }

  &.slidesjs-previous {
    background-position: 0 0;
  }

  &:hover.slidesjs-previous {
    background-position: 0 -18px;
  }

  &.slidesjs-play {
    width: 15px;
    background-position: -25px 0;
  }

  &:hover.slidesjs-play {
    background-position: -25px -18px;
  }

  &.slidesjs-stop {
    width: 18px;
    background-position: -41px 0;
  }

  &:hover.slidesjs-stop {
    background-position: -41px -18px;
  }
}

.slidesjs-pagination {
  margin: 7px 0 0;
  float: right;
  list-style: none;

  li {
    float: left;
    margin: 0 1px;

    a {
      display: block;
      width: 13px;
      height: 0;
      padding-top: 13px;
      background-image: url('/images/pagination.png');
      background-position: 0 0;
      float: left;
      overflow: hidden;

      &.active {
        background-position: 0 -13px;
      }

      &:hover {
        &.active {
          background-position: 0 -13px;
        }

        background-position: 0 -26px;
      }
    }
  }
}

#slides {
  a {

    &:link,
    &:visited {
      color: #333;
    }

    &:hover,
    &:active {
      color: #9e2020;
    }
  }

  display: none;
}

h2.contactsTitle {
  text-align: center;
  font: 700 34px/40px 'Noto Sans', sans-serif;
}

.slideContainer {
  margin: 0 auto;
}

.contact-item {
  width: 100%;

  span.photo {
    width: 100%;
    display: block;
    overflow: hidden;
    position: relative;
    border-radius: $border-radius;

    img {
      width: 100%;
      height: auto;
    }
  }

  b.name {
    width: 100%;
    display: block;
    text-align: center;
    font: 700 20px/24px 'Noto Sans', sans-serif;
  }

  a.eLink {
    @include animate(all);
    display: block;
    text-align: center;
    // padding: 4px;
    margin: 5px 0px 10px;
    // font: 700 16px/24px 'Noto Sans', sans-serif;
    font-size: $fs-normal;
    padding: 8px;
    background: $color-brand-blue;
    border-radius: $border-radius;
    color: $color-white;

    &:hover {
      background: $color-brand-blue-hover;
      text-decoration: none;
      box-shadow: 0 0 3px rgba(0, 0, 0, 0.5);
      cursor: pointer;
    }
  }
}

ul.contactsList {
  display: inline-flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
  position: relative;

  li {
    // flex: 1 0 45%;
    // width: 45%;
    // max-width: 45%;
    margin: 0px 10px 10px;
    width: 215px;

    // @include media-min-sm() {
    //   flex: 1 0 21%;
    //   width: 21%;
    //   max-width: 21%;
    // }

    // @include media-min-md() {
    //   flex: 1 0 21%;
    //   width: 21%;
    //   max-width: 21%;
    // }

    span.photo {
      width: 100%;
      display: block;
      overflow: hidden;
      position: relative;
      border-radius: $border-radius;

      img {
        width: 100%;
        height: auto;
      }
    }

    b.name {
      width: 100%;
      display: block;
      text-align: center;
      font: 700 20px/24px 'Noto Sans', sans-serif;
    }

    a.eLink {
      display: block;
      text-align: center;
      padding: 4px;
      margin: 5px 0px 10px;
      font: 700 20px/24px 'Noto Sans', sans-serif;
      background: $color-brand-blue;
      border-radius: $border-radius;
      color: $color-white;

      &:hover {
        background: #003C80;
        text-decoration: none;
        box-shadow: 0 0 3px rgba(0, 0, 0, 0.5);
        cursor: pointer;
      }
    }
  }
}

// .clearFix:after,
// .grid_c:after,
// #content .container:after,
// ul.contactsList:after {
//   clear: both;
//   content: ".";
//   display: block;
//   height: 0;
//   visibility: hidden;
// }

.gmap_canvas {
  @include aspect(620, 300);
  overflow: hidden;
  background: none !important;

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}
