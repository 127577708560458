.slide-container {
  margin: 0 auto;
  position: relative;
  z-index: 1;

  .slides {
    display: block;
    overflow: hidden;
  }

  .slide {
    height: 100%;
  }

  .slide-image {
    max-height: 100%;
    height: 100%;
    min-width: 100%;
  }
}
